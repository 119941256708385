import React, { memo } from 'react';
import TopBar from '../../../../components/TopBar';
import TopBarContent from './components/TopBarContent';
import Overview from '../components/Overview';
import Calendar from './components/Calendar';

const Home = () => {
  return (
    <>
      <TopBar>
        <TopBarContent />
      </TopBar>
      <div className="p-5">
        <Overview />
        <Calendar />
      </div>
    </>
  );
};

export default memo(Home);
