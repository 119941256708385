import posthog from 'posthog-js';

const env = process.env.REACT_APP_ENVIRONMENT;

if (env === 'production') {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  });
}

export default posthog;
