import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

// Custom Tooltip content
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex items-center custom-tooltip bg-white px-2 py-1 rounded-sm">
        <div
          className="w-2 h-2 relative rounded-[50%] z-[1]"
          style={{ backgroundColor: payload[0].payload.fill }}
        />
        {/* <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p> */}
        <p className="capitalize">
          {' '}
          <span className="text-xs font-semibold">
            {' '}
            {payload[0].value}%{' '}
          </span>{' '}
          {payload[0].name}
        </p>
      </div>
    );
  }

  return null;
};

const DonutChart = ({ data, legend, total, outerRadius, innerRadius }) => (
  <div style={{ width: '100%', height: '300px' }}>
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          innerRadius={innerRadius} // change the size of the hole by changing the innerRadius
          outerRadius={outerRadius} // change the size of the pie chart by changing the outerRadius
          fill="#8884d8"
          paddingAngle={0} // remove padding between slices
          dataKey="value" // value to be used for the pie chart
          // label
          stroke="none" // remove border
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <text
          x="50%"
          y="50%"
          fill="black"
          textAnchor="middle"
          dominantBaseline="centeral"
          fontSize={28}
          fontWeight={500}
        >
          {total}
        </text>
        <text
          x="50%"
          y="55%"
          fill="#797373"
          textAnchor="middle"
          dominantBaseline="centeral"
          fontSize={12}
        >
          total tickets
        </text>
        <Tooltip content={<CustomTooltip />} />
        {legend && <Legend />}
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default DonutChart;
