import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimeTrackerStore } from '../../../../../../state';
import { formatWorkTime } from '../../../../../../utils/helpers';
const CalendarDay = ({ day, year, month }) => {
  const { dailyLogs } = useTimeTrackerStore();
  const { selectedAgent } = useTimeTrackerStore();
  const navigate = useNavigate();
  const workTime = dailyLogs.find((log) => log.day === day)?.duration ?? 0;
  const formattedWorkTime = formatWorkTime(workTime);
  const onClickHandler = () => {
    navigate(
      `/pulse/time-diary/day-view?agentId=${selectedAgent}&day=${day}&month=${month}&year=${year}`
    );
  };

  return (
    <div className="flex flex-col w-full h-full border border-disableColor text-md text-semibold ">
      <div className="flex p-1">{day}</div>
      <div
        className={`flex text-textPrimary text-bold text-lg ${formattedWorkTime !== '00:00' ? 'bg-greenTag' : 'bg-disableColor'} p-1`}
      >
        {' '}
        {formattedWorkTime}{' '}
      </div>
      <div className="flex p-1 bg-bgGray cursor-pointer h-7">
        <p
          onClick={onClickHandler}
          className="text-highlightColor text-sm underline "
        >
          {formattedWorkTime !== '00:00' && (
            <>
              View TimeProofs
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
                className="inline text-highlightColor text-lg "
              >
                <g clip-path="url(#clip0_1741_6101)">
                  <path
                    d="M0.116109 6.78389C0.193528 6.8613 0.295001 6.9 0.396473 6.9C0.497946 6.9 0.599418 6.86128 0.676811 6.78389L5.60704 1.85366V3.7C5.60704 3.91896 5.78456 4.09648 6.00352 4.09648C6.22248 4.09648 6.4 3.91896 6.4 3.7V0.896478C6.4 0.677516 6.22248 0.5 6.00352 0.5H3.20001C2.98105 0.5 2.80353 0.677516 2.80353 0.896478C2.80353 1.11544 2.98105 1.29296 3.20001 1.29296H5.04631L0.116109 6.22319C-0.0387031 6.37802 -0.0387031 6.62907 0.116109 6.78389Z"
                    fill="#394DC6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1741_6101">
                    <rect
                      width="8"
                      height="8"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default CalendarDay;
