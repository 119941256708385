import React, { useEffect } from 'react';
import { useTimeTrackerStore } from '../../../../../../state';
import { useNavigate } from 'react-router-dom';
import SelectDropDown from '../../../../../../components/selectDropdown';
const TopBarContent = () => {
  const { agents, updateState, selectedAgent } = useTimeTrackerStore();
  const navigate = useNavigate();
  useEffect(() => {
    updateState('selectedAgent', agents?.[0]?._id);
  }, [agents]);

  const handleOnClick = () => {
    navigate('/pulse/time-diary');
  };

  return (
    <div className="flex items-center justify-between rounded-lg w-full">
      <div
        className="text-blue-600 text-lg font-bold cursor-pointer"
        onClick={handleOnClick}
      >
        Time Diary
      </div>
      <SelectDropDown
        options={agents}
        selectedOption={selectedAgent}
        handleOptionChange={(value) => {
          updateState('selectedAgent', value);
        }}
      />
    </div>
  );
};

export default TopBarContent;
