import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { capitalizeWords } from '../../../common/util';
import { useCategoryStore } from '../../../state';

const Sidebar = () => {
  const params = useParams();
  const { subCategories, categories, excludedCategories } = useCategoryStore();
  const categoriesNameList = categories.categoriesArray?.map((cat) => cat.name);
  const [expandedCategory, setExpandedCategory] = useState(params.category);
  const navigate = useNavigate();
  const posthog = usePostHog();

  const toggleCategory = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null); // Collapse if it's already expanded
    } else {
      setExpandedCategory(category); // Expand the new one
      navigate(`/pulse/${encodeURIComponent(category)}/`);
    }
  };

  return (
    <div className="bg-[#F5F5F5] w-full overflow-hidden border-r border-r-disableColor">
      <div className="p-4 text-black font-medium text-base border-b border-b-disableColor">
        Categories
      </div>
      <div className="overflow-y-auto overflow-x-hidden h-contentHeight max-w-[14rem]">
        {categoriesNameList?.map((category) => (
          <div key={category}>
            <div
              onClick={() => {
                toggleCategory(category);
                posthog.capture(`sidebar_${category}_clicked`);
              }}
              className={`flex items-center justify-between text-sm font-medium cursor-pointer px-4 py-2 no-underline border-b border-b-disableColor ${
                expandedCategory === category || params.category === category
                  ? 'text-black font-bold'
                  : 'text-textLight'
              }`}
            >
              <span className=" text-ellipsis break truncate" title={category}>
                {capitalizeWords(category)}
              </span>{' '}
              {expandedCategory === category ? <ChevronUp /> : <ChevronDown />}
            </div>
            {expandedCategory === category &&
              subCategories?.[category]
                .filter((cat) => !excludedCategories.includes(cat.name))
                .map((subCategory) => (
                  <NavLink
                    key={subCategory.name}
                    to={`/pulse/${encodeURIComponent(category)}/${encodeURIComponent(subCategory.name)}/tickets`}
                    className={({ isActive }) =>
                      `px-6 py-2 border-b border-b-disableColor flex items-center cursor-pointer text-xs no-underline${
                        isActive
                          ? ' text-white font-bold bg-highlightColor'
                          : ' text-textLight font-normal bg-transparent'
                      } hover:bg-highlightColor hover:text-white`
                    }
                  >
                    {capitalizeWords(subCategory.name)}
                  </NavLink>
                ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
