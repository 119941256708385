import React, { useEffect, useState } from 'react';
import { PersonCircle } from 'react-bootstrap-icons';
import { NavLink, useParams } from 'react-router-dom';
import { getTicketDetail } from '../../clients/ticket';
import {
  formatIsoStringToDateTime,
  formatMinutes,
  hasValue,
} from '../../common/util';
import DateRange from '../../components/date';
import useComponentTiming from '../../components/useComponentTiming';
import { useAuthStore, useCategoryStore, useTicketStore } from '../../state';
import Sidebar from './components/Sidebar';
import EstimatedCsat from './components/EstimatedCsat';

const TicketDetails = () => {
  useComponentTiming('View_ticket');
  const params = useParams();
  const [ticketDetails, setTicketDetails] = useState();
  const { tickets, fetchTickets } = useTicketStore();
  const { subCategories, fetchCategories } = useCategoryStore();
  const { account, token, dateSelectionData, accountType } = useAuthStore();
  const isFirstLoad = React.useRef(true);
  // useEffect(() => {
  //   if (!tickets.length) {
  //     (async () => {
  //       const startDate = addDays(startOfMonth(new Date()), 15)
  //         .toISOString()
  //         .substring(0, 10);

  //       const endDate = endOfMonth(new Date()).toISOString().substring(0, 10);
  //       const query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}`;
  //       await fetchTickets(token, query);
  //     })();
  //   }
  // }, []);

  useEffect(() => {
    if (!isFirstLoad.current) {
      (async () => {
        if (params.category && !params.subCategory) {
          const {
            range: { startDate, endDate },
          } = dateSelectionData;
          let query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=30&page=1&sortBy=last_message_datetime&sortOrder=desc`;
          if (params.category === 'miscellaneous') {
            query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=30&page=1&sortBy=last_message_datetime&sortOrder=desc`;
            subCategories &&
              subCategories['miscellaneous'].forEach((subCategory) => {
                query += `&subcategory=${subCategory.name}`;
              });
          }
          await fetchTickets(token, query);
        }
        if (params.category && params.subCategory) {
          const {
            range: { startDate, endDate },
          } = dateSelectionData;
          const query = `?account=${account}&category=${params.category}&subcategory=${params.subCategory}&startDate=${startDate}&endDate=${endDate}&size=30&page=1&sortBy=last_message_datetime&sortOrder=desc`;
          await fetchTickets(token, query);
        }
      })();
    }
    isFirstLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelectionData]);

  useEffect(() => {
    (async () => {
      const query = `?account=${account}`;
      const response = await getTicketDetail(token, params.ticketId, query);
      setTicketDetails({
        ...response.ticketDetails,
        ...response.ticketInsight,
        ...response.csatDetails,
      });
    })();
  }, [params.ticketId]);
  useEffect(() => {
    const {
      range: { startDate, endDate },
    } = dateSelectionData;
    const fetchData = async () => {
      const query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
      await fetchCategories(token, query);
    };
    fetchData();
  }, [dateSelectionData, account, token, fetchCategories]);

  return (
    <div className="flex bg-bgColor overflow-y-hidden pb-10">
      <div className="flex-[0.10]">
        <Sidebar />
      </div>

      <div className="flex-[0.90] overflow-hidden">
        <div className="flex flex-col w-full items-start justify-start font-onest min-w-full">
          <div className="flex flex-row w-full bg-white items-center justify-between px-5 py-2 gap-5 shrink-0 border-b border-b-disableColor border-t border-t-disableColor">
            <h3 className="relative text-black">
              <NavLink
                to="/pulse"
                className="no-underline text-black text-xl font-medium"
              >
                Pulse
              </NavLink>{' '}
              <span className="mx-2"> {`>`} </span>
              <NavLink
                to={`/pulse/${encodeURIComponent(params.category)}`}
                className="no-underline text-black text-xl font-medium capitalize"
              >
                {`${params.category}`}{' '}
              </NavLink>
              <span className="mx-2"> {`>`} </span>
              <NavLink
                to={`/pulse/${encodeURIComponent(params.category)}/tickets`}
                className="no-underline text-black text-xl font-medium capitalize"
              >
                Tickets
              </NavLink>
              <span className="mx-2"> {`>`} </span>
              <span className="text-slateblue text-xl font-bold">
                {params.ticketId}
              </span>
            </h3>
            <div className="flex flex-row items-center justify-center gap-4">
              <DateRange />
            </div>
          </div>
        </div>

        <div className="flex flex-col px-5 pb-10 text-textPrimary overflow-auto h-contentHeight">
          <p className="font-semibold text-lg text-textPrimary py-4">
            {' '}
            {tickets && tickets.length} Tickets Received{' '}
          </p>
          <div className="grid gap-5 grid-cols-3">
            <div className="col-span-2">
              <div className="bg-white col-span-2 grid grid-cols-8 overflow-y-auto h-contentHeight">
                <div className="flex flex-col overflow-y-auto border-r border-disableColor col-span-2">
                  <div className="p-4 text-black font-semibold text-sm">
                    {' '}
                    Ticket ID{' '}
                  </div>
                  {tickets &&
                    tickets.map((ticket, index) => (
                      <NavLink
                        to={
                          params.category && params.subCategory
                            ? `/pulse/${encodeURIComponent(params.category)}/${encodeURIComponent(params.subCategory)}/tickets/${ticket.ticketId}`
                            : `/pulse/${encodeURIComponent(params.category)}/tickets/${ticket.ticketId}`
                        }
                        style={{
                          borderTop: '1px solid #D9D9D9',
                          borderBottom: `${
                            index === tickets.length - 1
                              ? '1px solid #D9D9D9'
                              : 'none'
                          }`,
                        }}
                        className={({ isActive }) =>
                          `flex items-center cursor-pointer p-4 no-underline ${
                            isActive
                              ? 'text-white  font-bold bg-highlightColor'
                              : 'text-[#9F9F9F] font-normal bg-transparent'
                          }`
                        }
                      >
                        <span className="pl-2 text-xs">{ticket.ticketId}</span>
                      </NavLink>
                    ))}
                </div>
                <div className="flex flex-col col-span-6 px-4 text-textPrimary overflow-y-auto">
                  <div className="flex flex-col items-start justify-start">
                    <div className=" flex flex-col items-start justify-start gap-2 py-4">
                      <div className="capitalize text-sm font-semibold">
                        Conversation Summary
                      </div>
                      <div className="text-xs capitalize">
                        {ticketDetails?.summary}
                      </div>
                    </div>
                    <div className=" flex flex-col items-start justify-start gap-2 pb-4">
                      <div className="capitalize font-semibold text-sm">
                        Conversation
                      </div>
                      <div className="flex flex-row flex-wrap items-center justify-start text-sm gap-2 text-textLight">
                        <div className="font-medium ">Participants</div>
                        <div className="flex-1 flex flex-row items-center justify-start gap-2">
                          <div className="flex-1 flex flex-row items-center justify-start gap-1">
                            <PersonCircle fill="green" />
                            <div className="capitalize font-medium">
                              Customer
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-1">
                            <PersonCircle fill="blue" />
                            <div className="capitalize font-medium">Agent</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start text-textLight px-2 gap-4">
                    {ticketDetails?.ticket_data.map((msg) => (
                      <div className="flex flex-col items-start justify-start gap-2">
                        <div className="flex flex-col justify-start gap-1">
                          <div className="flex flex-row items-center justify-start gap-1">
                            <PersonCircle
                              fill={
                                msg.author_type === 'CUSTOMER'
                                  ? 'green'
                                  : 'blue'
                              }
                              className="h-7 w-7"
                            />
                            <div className="flex flex-col items-start justify-center gap-0.5">
                              {/* <div className="capitalize font-medium text-sm">
                                Tricia Russell
                              </div> */}
                              <div className="uppercase text-xs font-semibold">
                                {hasValue(msg.author_name)
                                  ? msg.author_name
                                  : msg.author_type}
                                {hasValue(msg.author_name) && (
                                  <div className=" text-3xs font-medium">
                                    {msg.author_type}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start gap-2 text-textPrimary text-xs">
                          <div className="break-word overflow-hidden flex-1 relative ">
                            {msg.body}
                          </div>
                        </div>
                        <div className="text-2xs">
                          {formatIsoStringToDateTime(msg.timestamp)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex flex-col gap-5">
              <div id="ticketInsights" className="grid gap-5">
                <EstimatedCsat ticketDetails={ticketDetails} />

                {ticketDetails && ticketDetails?.ticket_metric && (
                  <div className="rounded-sm bg-white overflow-hidden flex flex-col items-start justify-start p-3.5 border border-gray-200">
                    <div className="flex flex-col w-full">
                      <div className="text-sm font-semibold capitalize w-full border-b border-gray-200 pb-3">
                        Ticket Metrics
                      </div>
                      <div className="grid w-full text-xs gap-y-4 mt-2 grid-cols-3">
                        <div className="text-sm capitalize font-medium pr-5 col-span-2">
                          FRT
                        </div>
                        <div className="text-xs font-medium col-span-1">
                          {formatMinutes(
                            Math.floor(
                              ticketDetails?.ticket_metric
                                ?.first_response_time_in_minutes?.business
                            )?.toFixed(2)
                          ) || '-'}
                        </div>
                      </div>
                      <div className="grid w-full text-xs gap-y-4 mt-2 grid-cols-3">
                        <div className="text-sm capitalize font-medium pr-5 col-span-2">
                          Resolution Time
                        </div>
                        <div className="text-xs font-medium col-span-1">
                          {formatMinutes(
                            Math.round(
                              ticketDetails?.ticket_metric
                                ?.full_resolution_time_in_minutes?.business
                            )
                          ) || '-'}
                        </div>
                      </div>
                      <div className="grid w-full text-xs gap-y-4 mt-2 grid-cols-3">
                        <div className="text-sm capitalize font-medium pr-5 col-span-2">
                          Reopen Count
                        </div>
                        <div className="text-xs font-medium col-span-1">
                          {ticketDetails?.ticket_metric?.reopens || '-'}
                        </div>
                      </div>
                      <div className="grid w-full text-xs gap-y-4 mt-2 grid-cols-3">
                        <div className="text-sm capitalize font-medium pr-5 col-span-2">
                          Replies
                        </div>
                        <div className="text-xs font-medium col-span-1">
                          {ticketDetails?.ticket_metric?.replies || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="rounded-sm bg-white overflow-hidden flex flex-col items-start justify-start p-3.5 border border-gray-200">
                  <div className="text-sm font-semibold capitalize pr-5 border-b border-gray-200 w-full pb-3 mb-3">
                    Ticket Details
                  </div>
                  <img
                    className="self-stretch h-px max-w-full overflow-hidden shrink-0"
                    alt=""
                    src="/vector-42-2.svg"
                  />
                  <div className="flex items-start justify-start py-0 gap-3 text-gray-600">
                    <div className="text-sm capitalize font-medium">
                      Categories
                    </div>
                    <div className="rounded-sm overflow-hidden text-textLight flex items-center justify-center border px-2 border-gray-300">
                      <div className="text-xs capitalize font-mediu">
                        {ticketDetails?.category}
                      </div>
                    </div>
                    <div className="rounded-sm overflow-hidden text-textLight flex items-center justify-center border px-2 border-gray-300">
                      <div className="text-xs capitalize font-medium">
                        {ticketDetails?.subcategory}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
