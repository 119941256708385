import React from 'react';

const Shimmer = () => {
  return (
    <div className="p-4 w-full h-contentHeight bg-white">
      <div className="mb-4">
        <div className="h-6 w-1/4 bg-gray-200 shimmer"></div>
        <div className="h-6 w-1/3 bg-gray-200 shimmer mt-2"></div>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-4">
        <div className="h-24 bg-gray-200 shimmer"></div>
        <div className="h-24 bg-gray-200 shimmer"></div>
        <div className="h-24 bg-gray-200 shimmer"></div>
      </div>
      <div className="mb-4">
        <div className="h-6 w-1/4 bg-gray-200 shimmer"></div>
        <div className="h-6 w-1/3 bg-gray-200 shimmer mt-2"></div>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="h-12 bg-gray-200 shimmer"></div>
        <div className="h-12 bg-gray-200 shimmer"></div>
        <div className="h-12 bg-gray-200 shimmer"></div>
        <div className="h-12 bg-gray-200 shimmer"></div>
        <div className="h-12 bg-gray-200 shimmer"></div>
        <div className="h-12 bg-gray-200 shimmer"></div>
      </div>
    </div>
  );
};

const ShimmerWithSideBar = () => {
  return (
    <>
      <div className="flex">
        <div className="flex-1">
          <div className="h-12 bg-gray-200 shimmer"></div>
        </div>
        <div className="flex-3">
          <Shimmer />
        </div>
      </div>
    </>
  );
};
export { Shimmer, ShimmerWithSideBar };
