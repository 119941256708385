import React, { useState, useEffect } from 'react';
import Lightning from 'assets/icons/lighting.svg';
import { ChevronRight, ChevronUp } from 'react-bootstrap-icons';
import { getSubcategoryData } from 'api/agentPerformanceApi';

const AgentPerformanceLeaderboard = ({ agentData, agentId, account, start_date, end_date }) => {
    const [subcategoryData, setSubcategoryData] = useState({});
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 });
    const [isLoading, setIsLoading] = useState(false);

    const formattedData = agentData?.map(category => ({
        category: category.category,
        ticketsClosed: `${category.totalTickets}`,
        overall: `${(category.averageCSAT.overall * 20).toFixed(2)}%`,
        empathy: `${(category.averageCSAT.empathy * 20).toFixed(2)}%`,
        personalization: `${(category.averageCSAT.qualitySupport * 20).toFixed(2)}%`,
        responsiveness: `${(category.averageCSAT.agentResponsive * 20).toFixed(2)}%`,
        completeness: `${(category.averageCSAT.resolutionalCompleteness * 20).toFixed(2)}%`,
        emotionalPolarity: `${(category.averageCSAT.emotionalPolarity * 20).toFixed(2)}%`,
        averageResponseTime: `${Math.round(category.averageResponseTime)} min`,
        averageFirstResolutionTime: `${Math.round(category.averageFirstResolutionTime)} min`
    }));

    useEffect(() => {
        const fetchSubcategoryData = async (category) => {
            if (!subcategoryData[category]) {
                try {
                    setIsLoading(true);
                    const data = await getSubcategoryData(agentId, account, start_date, end_date, category);
                    setSubcategoryData(prevData => ({
                        ...prevData,
                        [category]: data
                    }));
                } catch (error) {
                    console.error('Error fetching subcategory data:', error);
                }
                finally {
                    setIsLoading(false);
                }
            }
        };

        if (expandedCategory) {
            fetchSubcategoryData(expandedCategory);
        }
    }, [expandedCategory, agentId, account, start_date, end_date, subcategoryData]);

    const handleRowClick = (category) => {
        setExpandedCategory(expandedCategory === category ? null : category);
    };

    const showTooltip = (e, content) => {
        setTooltip({
            visible: true,
            content,
            x: e.clientX,
            y: e.clientY
        });
    };

    const hideTooltip = () => {
        setTooltip({ ...tooltip, visible: false });
    };

    return (
        <div className="p-4 bg-white font-inter rounded">
            <h2 className="text-darkGrey font-semibold text-lg mb-4">Agent Performance by Category</h2>
            <div className="overflow-auto">
                <table className="min-w-full bg-white text-sm">
                    <thead>
                        <tr>
                            <th className="py-4 px-8 text-left font-semibold"></th>
                            <th className="py-4 px-8 text-left font-semibold"></th>
                            <th className="py-4 px-8 text-center font-semibold border border-disableColor" colSpan="6">
                                <span className="flex items-center justify-center w-full">
                                    <img src={Lightning} alt="Rating" className="w-4 h-4 mr-2" />
                                    Rating
                                </span>
                            </th>
                        </tr>
                        <tr className="border-t border-disableColor">
                            <th className="py-4 px-8 text-left font-semibold border-l">Category</th>
                            <th className="py-4 px-8 text-left font-semibold">Tickets&nbsp;Closed</th>
                            <th className="py-4 px-8 text-center font-normal bg-[#E8EAF6] bg-opacity-40 border-l">Overall</th>
                            <th className="py-4 px-8 text-center font-normal">Empathy</th>
                            <th className="py-4 px-8 text-center font-normal">Personalization</th>
                            <th className="py-4 px-8 text-center font-normal">Responsiveness</th>
                            <th className="py-4 px-8 text-center font-normal">Completeness</th>
                            <th className="py-4 px-8 text-center font-normal border-r">Emotional Polarity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formattedData.map((category, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    onClick={() => handleRowClick(category.category)}
                                    className={`cursor-pointer hover:bg-[#E8EAF6] hover:bg-opacity-40 ${index % 2 === 0 && !expandedCategory ? 'bg-[#eee] bg-opacity-40' : ''} ${index === formattedData.length - 1 ? 'border-b' : ''} `}
                                >
                                    <td className="py-4 px-8 border-l truncate flex items-center">
                                        <span className="mr-2">
                                            {expandedCategory === category.category && !isLoading ?
                                                <ChevronUp className="w-4 h-4" /> :
                                                <ChevronRight className="w-4 h-4" />
                                            }
                                        </span>
                                        {category.category}

                                    </td>
                                    <td className="py-4 px-8">{category.ticketsClosed}</td>
                                    <td className={`py-4 px-8 text-center bg-[#E8EAF6] bg-opacity-40 border-l ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.overall}</td>
                                    <td className={`py-4 px-8 text-center ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.empathy}</td>
                                    <td className={`py-4 px-8 text-center ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.personalization}</td>
                                    <td className={`py-4 px-8 text-center ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.responsiveness}</td>
                                    <td className={`py-4 px-8 text-center ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.completeness}</td>
                                    <td className={`py-4 px-8 text-center border-r ${expandedCategory !== null && expandedCategory !== category.category ? 'opacity-20' : ''}`}>{category.emotionalPolarity}</td>
                                </tr>
                                {expandedCategory === category.category && subcategoryData[category.category] && subcategoryData[category.category].length !== 0 && !isLoading && (
                                    subcategoryData[category.category].map((subcategory, subIndex) => (
                                        <tr key={`${index}-${subIndex}`} className={`border  ${subIndex % 2 === 0 ? 'bg-[#EEE] bg-opacity-40' : ''}`}>
                                            <td className="py-2 px-4 truncate pl-16">
                                                <span
                                                    className="truncate inline-block max-w-[200px]"
                                                    onMouseEnter={(e) => showTooltip(e, subcategory.subCategory)}
                                                    onMouseLeave={hideTooltip}
                                                >
                                                    {subcategory.subCategory}
                                                </span>
                                            </td>
                                            <td className="py-2 px-8">{subcategory.totalTickets}</td>
                                            <td className="py-2 px-8 text-center bg-[#E8EAF6] bg-opacity-40 border-l">{`${(subcategory.averageCSAT.overall * 20).toFixed(2)}%`}</td>
                                            <td className="py-2 px-8 text-center">{`${(subcategory.averageCSAT.empathy * 20).toFixed(2)}%`}</td>
                                            <td className="py-2 px-8 text-center">{`${(subcategory.averageCSAT.qualitySupport * 20).toFixed(2)}%`}</td>
                                            <td className="py-2 px-8 text-center">{`${(subcategory.averageCSAT.agentResponsive * 20).toFixed(2)}%`}</td>
                                            <td className="py-2 px-8 text-center">{`${(subcategory.averageCSAT.resolutionalCompleteness * 20).toFixed(2)}%`}</td>
                                            <td className="py-2 px-8 text-center">{`${(subcategory.averageCSAT.emotionalPolarity * 20).toFixed(2)}%`}</td>
                                        </tr>
                                    ))
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            {tooltip.visible && (
                <div
                    className="absolute bg-black text-white p-2 rounded text-sm cursor-pointer"
                    style={{ left: `${tooltip.x}px`, top: `${tooltip.y}px`, transform: 'translate(10px, 10px)' }}
                >
                    {tooltip.content}
                </div>
            )}
        </div>
    );
};

export default AgentPerformanceLeaderboard;