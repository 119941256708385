import baseAPI from './baseAPI';

export const getOverview = async (
  token,
  account,
  category,
  startDate,
  endDate,
  options = {},
  subcategory = []
) => {
  let query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
  if (category instanceof Array)
    category.forEach((c) => (query += `&category=${c}`));
  else query += `&category=${category}`;
  if (subcategory.length > 0)
    subcategory.forEach((s) => (query += `&subcategory=${s}`));
  const resp = await baseAPI.get(`/common/overview${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-access-token': token,
    },
    signal: options.signal,
  });
  if (!resp.ok) {
    throw new Error('Failed to fetch overview');
  }
  return await resp.json();
};
