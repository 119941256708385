import { create } from 'zustand';
import { getCategoryTickets } from '../clients/ticket';

export const useTicketStore = create((set) => ({
  tickets: [],
  totalTickets: 0,
  ticketLoading: false,
  ticketError: null,
  fetchTickets: async (accessToken, query) => {
    set({ ticketLoading: true, categoriesError: null });
    try {
      const response = await getCategoryTickets(accessToken, query);
      set({
        tickets: response.tickets,
        totalTickets: response.total,
        ticketLoading: false,
      });
    } catch (error) {
      set({ ticketError: error.message, ticketLoading: false });
      console.log(error, '--error');
    }
  },
}));
