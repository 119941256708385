import React, { useState } from 'react';
import { ChevronRight } from 'react-bootstrap-icons';
import { useTicketQueryStore } from '../../../state/useTicketQuery';

const TicketDisplay = () => {
  const { tickets, searchLoading } = useTicketQueryStore();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [expandedTicketId, setExpandedTicketId] = useState(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTicketClick = (id) => {
    if (expandedTicketId === id) {
      setExpandedTicketId(null); // Collapse the currently expanded ticket
    } else {
      setExpandedTicketId(id); // Expand the new ticket
    }
  };

  return (
    <div className="flex rounded-2xl shadow-2xl bg-white my-4 justify-center w-full lg:w-[75%] max-h-screen mx-auto overflow-hidden">
      <div className="flex flex-col border border-[#d9d9d9] cursor-pointer w-full">
        <button
          onClick={toggleCollapse}
          className="p-2 bg-gray-200 hover:bg-gray-300 rounded-t-xl w-full text-left font-semibold"
        >
          {isCollapsed ? 'Show Tickets' : 'Hide Tickets'}{' '}
        </button>
        {!isCollapsed && (
          <div className="flex flex-col overflow-auto">
            {tickets?.length > 0 ? (
              tickets.map((ticket) => (
                <div
                  key={ticket[0]} // Assuming ticket[0] is unique
                  onClick={() => handleTicketClick(ticket[0])}
                  className="flex flex-col w-full p-4 border-b border-gray-300 hover:bg-gray-200"
                >
                  <div className="flex justify-between items-center">
                    <span className="font-bold">Ticket ID: {ticket[0]}</span>
                    <ChevronRight
                      className={`${expandedTicketId === ticket[0] ? 'rotate-90' : ''}`}
                    />
                  </div>
                  {expandedTicketId === ticket[0] ? (
                    <div className="text-gray-800 mt-2">
                      <p> {ticket[1]}</p>
                    </div>
                  ) : (
                    <div className="text-gray-800 mt-2 overflow-hidden text-ellipsis whitespace-nowrap">
                      {ticket[1]}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center p-4">No tickets found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketDisplay;
