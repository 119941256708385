import React, { useEffect, useState } from 'react';
import XSVG from '../../../../assets/icons/X.svg';
import ConfigureSvg from '../../../../assets/icons/configure.svg';
import withOutsideClick from '../../../../components/withOutsideClick';
import { useCategoryStore } from '../../../../state';
const ToggleButton = ({ isActive, onClick }) => (
  <button
    className={`w-10 h-6 flex items-center rounded-full p-1 transition-colors ${
      isActive ? 'bg-limegreen' : 'bg-disableColor'
    }`}
    onClick={onClick}
  >
    <div
      className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
        isActive ? 'translate-x-4' : ''
      }`}
    ></div>
  </button>
);

const ConfirmationPopup = ({ isOpen, onClose, handleSave }) => {
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm z-20">
        <div className="bg-white p-4 rounded shadow-lg w-[92%] max-w-md text-sm">
          <p className="text-left text-gray-700 font-inter py-4">
            Your saved configurations will be applied organization-wide and
            visible to other users.
          </p>
          <p className="text-left text-gray-500 text-xs font-inter">
            *You can change your selection in future.
          </p>
          <div className="flex space-x-4 mt-4">
            <button
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded w-2/5 text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-highlightColor text-white rounded w-3/5 text-sm"
              onClick={handleSave}
            >
              Confirm&nbsp;Selection
            </button>
          </div>
        </div>
      </div>
    )
  );
};

const CategoryConfigure = ({
  categoryName = null,
  categoryNameList = [],
  excludedCategories = [],
  isOpen,
  toggleVisibility,
}) => {
  const { updateExcludedCategoriesCall } = useCategoryStore();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    const initialMetrics = categoryNameList.reduce((acc, metric) => {
      acc[metric] = !excludedCategories.includes(metric);
      return acc;
    }, {});
    setMetrics(initialMetrics);
  }, [categoryNameList.length, excludedCategories.length]);

  const toggleMetric = (metric) => {
    setMetrics((prevMetrics) => ({
      ...prevMetrics,
      [metric]: !prevMetrics[metric],
    }));
  };
  const handleSave = async () => {
    try {
      const newExcluded = Object.keys(metrics).filter(
        (metric) => !metrics[metric]
      );
      await updateExcludedCategoriesCall(categoryName, newExcluded);
      toggleConfirmationPopup();
      toggleVisibility();
    } catch (e) {
      console.error('Error Updating Exclude Categories', e);
      toggleConfirmationPopup();
    }
  };
  const toggleConfirmationPopup = () => {
    setConfirmationPopup((confirmationPopup) => !confirmationPopup);
  };

  return (
    <div className="relative text-base">
      <button
        className="flex items-center gap-2 text-highlightColor font-inter font-semibold text-md"
        onClick={toggleVisibility}
      >
        {isOpen ? (
          <img src={XSVG} alt="X" className=" h-3 w-3" />
        ) : (
          <img src={ConfigureSvg} alt="configure" className="h-4 w-4" />
        )}
        Configure
      </button>
      {isOpen && (
        <>
          <div className="absolute z-10 top-full right-0 mt-2 border border-gray-200 bg-white shadow-xl  overflow-hidden ">
            <div className="p-4 min-w-[400px]">
              <div className=" min-h-[30vh]  max-h-[60vh] overflow-y-auto overflow-x-hidden">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold">Category</h2>
                  <h2 className="font-semibold">Include in Metrics</h2>
                </div>
                {categoryNameList.length === 0 && (
                  <div className="flex justify-center items-center h-24">
                    <p className="text-sm text-gray-500 font-inter">
                      No metrics available
                    </p>
                  </div>
                )}
                {categoryNameList.map((metric, idx) => (
                  <div className="w-full" key={idx}>
                    <div className="w-full border-t border-disableColor"></div>
                    <div
                      key={metric}
                      className="flex justify-between items-center py-2"
                    >
                      <span className="capitalize text-sm font-medium w-4/5">
                        {metric.replace(/([A-Z])/g, ' $1').trim()}
                      </span>
                      <ToggleButton
                        isActive={metrics[metric]}
                        onClick={() => toggleMetric(metric)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="mt-4 p-2 bg-highlightColor text-white font-semibold rounded w-full text-sm"
                onClick={toggleConfirmationPopup}
              >
                Save Selection
              </button>
            </div>
          </div>

          <ConfirmationPopup
            isOpen={confirmationPopup}
            onClose={toggleConfirmationPopup}
            handleSave={handleSave}
          />
        </>
      )}
    </div>
  );
};

export default withOutsideClick(CategoryConfigure);
