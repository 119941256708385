import { marked } from 'marked';
import { useEffect, useState } from 'react';
import {
  ChevronRight,
  ChevronUp,
  Person,
  PersonCircle,
} from 'react-bootstrap-icons';
import { Link, useSearchParams } from 'react-router-dom';
const baseURL = process.env.REACT_APP_DEV_SERVER_URL;
const CopilotConversations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(searchParams.get('id') || 0);
  const [sessionId, setSessionId] = useState(searchParams.get('session'));
  const [phoneNumber, setPhoneNumber] = useState(searchParams.get('ph'));
  const [sessionDetails, setSessionDetails] = useState({});
  const [moving, setMoving] = useState(false);

  const fetchChats = () => {
    const url = `${baseURL}/copilot/chats`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchChats();
    if (sessionId && phoneNumber) {
      fetchSessionDetails(sessionId, phoneNumber);
    }
    let interval = setInterval(() => {
      fetchChats();
      if (sessionId && phoneNumber) {
        fetchSessionDetails(sessionId, phoneNumber);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const fetchSessionDetails = (sessionId, phoneNumber) => {
    if (sessionId === '' || phoneNumber === '') return;
    const url = `${baseURL}/copilot/chats/${phoneNumber}/${sessionId}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setSessionDetails(json[0]);
      });
  };

  const moveToAgent = async (sessionId) => {
    setMoving(true);
    const url = `${baseURL}/copilot/directMoveToFreshchat`;
    const data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
      }),
    });
    const response = await data.json();
    setMoving(false);
    if (response?.success) {
      fetchChats();
      if (sessionId && phoneNumber) {
        fetchSessionDetails(sessionId, phoneNumber);
      }
    }
  };

  return (
    <div className="overflow-hidden h-full">
      <div className="p-4  text-xl font-bold border-b border-b-disableColor ">
        <div>
          <Link to="/copilot">
            <span className=" text-primaryColor">Copilot</span>
          </Link>
          {' > '}
          <span className="text-highlightColor font-onest">Conversations</span>
        </div>
      </div>
      {loading ? (
        <div className="p-4">Loading...</div>
      ) : (
        <div className="p-4 overflow-auto h-contentHeight">
          <div className=" text-neutral-700 font-semibold text-lg">
            {data.length} Conversations
          </div>
          {data.length > 0 && (
            <div className="flex mt-[1rem] bg-white h-contentHeight">
              <div className="flex-[0.2] overflow-y-auto h-full  border border-neutral-200">
                {data.map((conversation, index) => (
                  <div
                    key={conversation.phoneNumber}
                    className={`cursor-pointer flex items-center justify-between p-4 bg-whiterounded-sm ${conversation.phoneNumber == phoneNumber ? ' bg-bgColor' : ''}`}
                    onClick={() => {
                      setSelected(index);
                      setSearchParams((params) => {
                        params.set('id', index);
                        params.set('session', '');
                        params.set('ph', conversation.phoneNumber);
                        return params;
                      });
                    }}
                  >
                    <div>
                      <div className="text-neutral-700 font-semibold flex items-center">
                        <PersonCircle className="mr-2" />
                        {conversation['user']['name']}
                      </div>
                      <div className="text-neutral-500 mt-1">
                        {conversation['user']['phoneNumber']}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex-[0.6] overflow-y-auto h-full p-4 border">
                {data[selected]['sessions'].map((session) => {
                  const date = new Date(session.createdAt);

                  // Use Intl.DateTimeFormat to format the date in a human-readable way
                  // Customize options as needed for your desired output
                  const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  };

                  const formattedDate = new Intl.DateTimeFormat(
                    'en-US',
                    options
                  ).format(date);

                  return (
                    <>
                      <div
                        className="cursor-pointer bg-white border-b border-neutral-200 rounded-sm overflow-hidden"
                        onClick={() => {
                          if (sessionId !== session['sessionId']) {
                            setSessionId(session['sessionId']);
                            setPhoneNumber(
                              data[selected]['user']['phoneNumber']
                            );
                            fetchSessionDetails(
                              session['sessionId'],
                              data[selected]['user']['phoneNumber']
                            );
                            setSearchParams((params) => {
                              params.set('session', session['sessionId']);
                              params.set(
                                'ph',
                                data[selected]['user']['phoneNumber']
                              );
                              params.set('id', selected);
                              return params;
                            });
                          } else {
                            setSessionId('');
                            setPhoneNumber('');
                            setSessionDetails({});
                          }
                        }}
                      >
                        <div className="flex items-center py-4">
                          {sessionId !== session['sessionId'] ? (
                            <ChevronRight />
                          ) : (
                            <ChevronUp />
                          )}
                          <div className="flex justify-between  items-center gap-4 flex-1 ml-2">
                            <div className="text-neutral-700 font-semibold">
                              {formattedDate}
                            </div>
                            <div className="text-neutral-500">
                              {session.totalMessages} Messages
                            </div>
                            <div className={`justify-end flex`}>
                              {session['routed'] ? (
                                <div className="bg-zinc-300  text-xs font-medium p-2 w-fit rounded-sm px-4">
                                  ROUTED
                                </div>
                              ) : (
                                <div
                                  className=" bg-green-200 text-xs font-medium p-2 w-fit rounded-sm px-4"
                                  onClick={(e) => {
                                    setMoving(true);
                                    moveToAgent(session['sessionId']);
                                  }}
                                >
                                  {moving && sessionId === session['sessionId']
                                    ? 'Wait..'
                                    : 'MOVE TO AGENT'}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {sessionId === session['sessionId'] &&
                          sessionDetails &&
                          Object.keys(sessionDetails).length > 0 && (
                            <div className="flex flex-col h-80 overflow-auto bg-bgColor p-4 rounded-sm">
                              {sessionDetails['messages'].map((message) => {
                                const date = new Date(message['timestamp']);
                                const formattedDate = new Intl.DateTimeFormat(
                                  'en-US',
                                  {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                  }
                                ).format(date);
                                return (
                                  <div className={`rounded-sm py-4`}>
                                    <div className="flex items-center">
                                      <div
                                        className={`rounded-full p-2 ${
                                          message['role'] === 'assistant'
                                            ? 'bg-gradient-kim from-pink-400 to-indigo-700'
                                            : 'bg-green-500'
                                        } mr-2`}
                                      >
                                        <Person
                                          className={` text-white w-6 h-6`}
                                        />
                                      </div>
                                      <span className="capitalize text-stone-400 text-sm font-medium ">
                                        {message['role']}
                                      </span>
                                    </div>
                                    <div className=" mt-3 capitalize font-normal text-sm">
                                      <p
                                        className=" flex-wrap break-words"
                                        dangerouslySetInnerHTML={{
                                          __html: marked(message['content']),
                                        }}
                                      />
                                    </div>
                                    <div className="mt-1 font-normal text-xs text-neutral-400">
                                      {formattedDate}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CopilotConversations;
