import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { PersonCircle } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import GreenKimLogo from '../assets/kim-white.svg';
import { useAuthStore } from '../state';

const Header = () => {
  const account = useAuthStore((state) => state.account);
  const [openLogoutDiv, setOpenLogoutDiv] = useState(false);
  const { logout } = useAuthStore();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const handleLogout = async () => {
    posthog.capture('Brand_logout');
    posthog.reset(true);
    await logout();
    navigate('/login');
  };
  return (
    <div className="bg-primaryColor text-white flex items-center p-4 relative">
      <div className="flex-[0.2]">
        <img alt="kim-logo" src={GreenKimLogo} className="h-6" />
      </div>
      <div className="flex-1 text-right text-md relative">
        <div className="flex items-center justify-end relative">
          <div className="mr-4 capitalize">{account}</div>
          <PersonCircle
            width={32}
            height={32}
            className="cursor-pointer"
            onClick={() => setOpenLogoutDiv(!openLogoutDiv)}
          />
        </div>
        {openLogoutDiv && (
          <div className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg z-20 opacity-100">
            <button
              className="text-black font-semibold flex items-center justify-center h-10 w-24 rounded-lg"
              onClick={(e) => handleLogout(e)}
            >
              LOGOUT
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
