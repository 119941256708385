import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TopBar from 'components/TopBar'
import TopBarContent from './topBarContent'
import AgentPerformance from './AgentPerformance'
import { useAuthStore } from 'state'
import { getAgentCategoryData, getAgentsPerformance } from 'api/agentPerformanceApi'
import { useNavigate } from 'react-router-dom'

const AgentView = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [selectedCategoriesNames, setSelectedCategoriesNames] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const { account, dateSelectionData } = useAuthStore();
    const { id } = useParams();
    const [agents, setAgents] = useState([]);
    const handleAgentChange = (data) => {
        navigate(`/pulse/agent/${data}`);
    }
    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const {
                    range: {
                        startDate,
                        endDate
                    }
                } = dateSelectionData;
                const data = await getAgentsPerformance(
                    account,
                    startDate,
                    endDate
                );
                setAgents(data);

            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        };
        fetchAgents();
    }, [account, dateSelectionData]);


    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const { range: { startDate, endDate } } = dateSelectionData;
                const data = await getAgentCategoryData(id, account, startDate, endDate);
                setCategories(data);
                setSelectedCategoriesNames(data.map(category => category.category));
                setSelectedCategories(data);
            } catch (error) {
                console.error('Error fetching agent data:', error);
            }
        };
        fetchCategoryData();
    }, [id, account, dateSelectionData]);
    const handleCategoryChange = (data) => {
        setSelectedCategoriesNames(data);
        setSelectedCategories(categories.filter(category => data.includes(category.category)));
    }

    return (
        <>
            <div className="bg-bgColor min-h-[100vh]">
                <TopBar >
                    <TopBarContent handleOptionsChange={handleCategoryChange} allOptions={categories.map(category => category.category)} selectedOptions={selectedCategoriesNames}
                        agents={agents}
                        selectedAgent={id}
                        handleAgentChange={handleAgentChange}
                        selectedCategories={selectedCategories}
                    />
                </TopBar>
                <AgentPerformance categories={selectedCategories} />
            </div>

        </>
    )
}

export default AgentView