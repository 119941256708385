import React from 'react';
import { useTicketQueryStore } from '../../../state/useTicketQuery';
const SearchBar = () => {
  const {
    userId,
    noOfTickets,
    searchQuery,
    setTickets,
    setSearchQuery,
    chatQuery,
    setChatQuery,
    addUserMessage,
    addCopilotMessage,
    setChatLoading,
  } = useTicketQueryStore();
  const HOST_URL =
    process.env.REACT_APP_PUBLIC_TICKET_QUERY_HOST_URL ||
    'http://localhost:8000';

  const handleSearchQuery = async () => {
    try {
      addUserMessage(chatQuery);
      setChatQuery('');
      setChatLoading(true);
      const response = await fetch(
        `${HOST_URL}/chat?user_id=${userId}&chat_query=${chatQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      const jsonBody = JSON.parse(data.body);

      if (response.ok) {
        addCopilotMessage(jsonBody.response);
      } else {
        addCopilotMessage(
          'Sorry, I am unable to process your request at the moment.'
        );
      }
    } catch (error) {
      // console.error(error);
      addCopilotMessage(
        'Sorry, I am unable to process your request at the moment.'
      );
    } finally {
      setChatLoading(false);
    }
  };

  return (
    <div className="w-1/2">
      <div className="flex rounded-2xl ring-1 ring-slate-400 bg-white mt-1 flex-1 shadow-2xl">
        <input
          type="text"
          value={chatQuery}
          onChange={(e) => setChatQuery(e.target.value)}
          className=" rounded-2xl p-4 w-full outline-none text-base"
          placeholder="Type your Doubts here..."
        />

        <button
          type="submit"
          className="ml-4 px-4 py-2 bg-ascentColor text-white rounded-md hover:bg-darkerAscentColor focus:outline-none"
          onClick={handleSearchQuery}
        >
          Ask Query
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
