import { create } from 'zustand';
import { getCategories } from '../clients/category';
import { getOverview } from '../api/categoriesApi';
import {
  getExcludedCategories,
  updateExcludedCategories,
} from '../api/configureApi';
import { useAuthStore } from './auth';

export const useCategoryStore = create((set) => ({
  categories: [],
  subCategories: [],
  categoriesError: null,
  //for displaying the details
  categoriesNameList: [],
  categoriesLoading: false,
  allCategories: [], // all categories in given range of date
  selectedCategoriesName: [], // selected categories are with out excluded and spam, junk, trash, fraud and in local storage
  excludedCategories: [], //list of name of exluded
  overviewDetails: {}, // overview details of selected categories
  overviewLoading: false,
  fetchCategories: async (accessToken, query) => {
    set({ categoriesLoading: true, categoriesError: null });
    try {
      const fetchCategories = await getCategories(accessToken, query);
      set({
        categories: fetchCategories,
        categoriesNameList: fetchCategories?.categoriesArray?.map(
          (cat) => cat.name
        ),
        subCategories: fetchCategories?.sortedSubcategories,
        categoriesLoading: false,
      });
    } catch (error) {
      set({ categoriesError: error.message, categoriesLoading: false });
      console.log(error, '--error');
    }
  },

  fetchTopLevelCategoriesDetail: async () => {
    set({
      categoriesLoading: true,
      categoriesError: null,
      overviewLoading: true,
    });
    try {
      const { token, account, dateSelectionData } = useAuthStore.getState();
      const {
        range: { startDate, endDate },
      } = dateSelectionData;
      const query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
      const fetchCategories = await getCategories(token, query);
      const fetchExcludedCategories = await getExcludedCategories(
        token,
        account
      );

      const excludedCategories = fetchExcludedCategories.data.excluded ?? [];

      const includedCategories =
        fetchCategories?.categoriesArray?.filter(
          (cat) => !excludedCategories.includes(cat.name)
        ) ?? [];
      const includedCategoriesNameList =
        includedCategories?.map((cat) => cat.name) ?? [];
      const localExcluded =
        JSON.parse(localStorage.getItem('excludedCategories')) ?? [];
      const _selectedCategoriesName = includedCategoriesNameList.filter(
        (cat) =>
          !localExcluded?.includes(cat) &&
          !['spam', 'junk', 'trash', 'fraud'].includes(cat)
      );

      const fetchOverview = await getOverview(
        token,
        account,
        _selectedCategoriesName,
        dateSelectionData.range.startDate,
        dateSelectionData.range.endDate
      );
      set({
        categories: fetchCategories,
        subCategories: fetchCategories.sortedSubcategories,
        excludedCategories: excludedCategories,
        allCategories: fetchCategories?.categoriesArray,
        categoriesNameList: includedCategoriesNameList,
        selectedCategoriesName: _selectedCategoriesName,
        overviewDetails: fetchOverview?.[0],
        categoriesLoading: false,
        overviewLoading: false,
      });
    } catch (error) {
      set({
        categoriesError: error.message,
        categoriesLoading: false,
        overviewLoading: false,
      });
      console.log(error, '--error');
    }
  },
  fetchDataInCategoryDetail: async (categoryName) => {
    try {
      set({
        overviewLoading: true,
        categoriesError: null,
        categoriesLoading: true,
      });
      const { token, account, dateSelectionData } = useAuthStore.getState();
      const query = `?account=${account}&start_date=${dateSelectionData.range.startDate}&end_date=${dateSelectionData.range.endDate}`;
      const fetchCategories = await getCategories(token, query);
      const fetchExcludedCategories = await getExcludedCategories(
        token,
        account,
        categoryName
      );
      const allCategories =
        fetchCategories?.sortedSubcategories?.[categoryName] ?? [];
      const excludedSubCategories = fetchExcludedCategories.data.excluded ?? [];
      const includedSubCategories = allCategories.filter(
        (cat) => !excludedSubCategories.includes(cat.name)
      );
      const includedSubCategoriesName = includedSubCategories.map(
        (cat) => cat.name
      );

      const localExcluded =
        JSON.parse(localStorage.getItem('excludedSubCategories')) ?? [];
      const _selectedCategoriesName = includedSubCategoriesName.filter(
        (cat) =>
          !localExcluded?.includes(cat) &&
          !['spam', 'junk', 'trash', 'fraud'].includes(cat)
      );

      const fetchOverview = await getOverview(
        token,
        account,
        categoryName,
        dateSelectionData.range.startDate,
        dateSelectionData.range.endDate,
        {},
        _selectedCategoriesName
      );
      set({
        categories: fetchCategories,
        subCategories: fetchCategories.sortedSubcategories,
        allCategories: allCategories,
        categoriesNameList: includedSubCategoriesName,
        selectedCategoriesName: _selectedCategoriesName,
        excludedCategories: excludedSubCategories,
        overviewDetails: fetchOverview[0],
        categoriesLoading: false,
        overviewLoading: false,
      });
    } catch (error) {
      set({
        categoriesError: error.message,
        categoriesLoading: false,
        overviewLoading: false,
      });
      console.log(error, '--error');
    }
  },

  updateState: (key, value) =>
    set((state) => ({
      ...state,
      [key]: value,
    })),
  updateExcludedCategoriesCall: async (categoryName, excluded) => {
    try {
      set({ overviewLoading: true });
      const { token, account, dateSelectionData } = useAuthStore.getState();
      const { allCategories } = useCategoryStore.getState();
      await updateExcludedCategories(token, account, categoryName, excluded);
      let includedCategoriesName = allCategories
        .filter((cat) => !excluded.includes(cat.name))
        .map((cat) => cat.name);

      let selectedCategoriesName = includedCategoriesName;
      if (!categoryName) {
        const localExcluded =
          JSON.parse(localStorage.getItem('excludedCategories')) ?? [];
        selectedCategoriesName = includedCategoriesName.filter(
          (cat) =>
            !localExcluded?.includes(cat) &&
            !['spam', 'junk', 'trash', 'fraud'].includes(cat)
        );
      } else {
        const localExcluded =
          JSON.parse(localStorage.getItem('excludedSubCategories')) ?? [];
        selectedCategoriesName = includedCategoriesName.filter(
          (cat) =>
            !localExcluded?.includes(cat) &&
            !['spam', 'junk', 'trash', 'fraud'].includes(cat)
        );
      }
      let fetchOverview;
      if (!categoryName) {
        fetchOverview = await getOverview(
          token,
          account,
          selectedCategoriesName,
          dateSelectionData.range.startDate,
          dateSelectionData.range.endDate
        );
      } else {
        fetchOverview = await getOverview(
          token,
          account,
          categoryName,
          dateSelectionData.range.startDate,
          dateSelectionData.range.endDate,
          {},
          selectedCategoriesName
        );
      }
      set({
        excludedCategories: excluded,
        categoriesNameList: includedCategoriesName,
        selectedCategoriesName: selectedCategoriesName,
        overviewDetails: fetchOverview?.[0],
      });
    } catch {
      console.error('failed to update excluded categories');
    } finally {
      set({ overviewLoading: false });
    }
  },
}));
