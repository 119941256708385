import React from 'react';
import ReactMarkdown from 'react-markdown';
import { BarLoader } from 'react-spinners';

import { useTicketQueryStore } from '../../../state/useTicketQuery';

const TicketDisplay = () => {
  const { tickets, conversation, chatLoading } = useTicketQueryStore();
  // console.log(conversation);

  return (
    <div
      className="flex rounded-2xl shadow-2xl bg-white my-4 justify-center mx-auto"
      style={{ width: '75%' }}
    >
      <div className=" cursor-pointer flex-col w-full overflow-auto">
        {conversation &&
          conversation.map((item, id) => (
            <div
              key={id}
              className="flex flex-col w-full p-4 border-b border-gray-300"
            >
              <span className="font-bold mb-2">
                {item.from === 'copilot' ? 'Copilot' : 'You'}
              </span>
              <div className="text-sm text-gray-500">
                <ReactMarkdown>{item.message}</ReactMarkdown>
              </div>
            </div>
          ))}
        {chatLoading && (
          <div className="w-full flex justify-center">
            <BarLoader color="#000" className="m-5 w-full" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketDisplay;
