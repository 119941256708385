import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_DEV_SERVER_URL;
const ToggleSwitch = ({ enabled, onChange }) => {
  // Use React state to manage the toggle state
  const [isEnabled, setIsEnabled] = useState(enabled);

  const handleToggle = () => {
    const newState = !isEnabled;
    setIsEnabled(newState);
    onChange(newState);
  };

  return (
    <div
      className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${
        isEnabled ? ' bg-highlightColor' : 'bg-gray-400'
      }`}
      onClick={handleToggle}
    >
      {/* The toggle circle */}
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
          isEnabled ? 'translate-x-6' : 'translate-x-0'
        }`}
      ></div>
    </div>
  );
};

const TextInput = ({ placeholder, value, onChange }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full p-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
    />
  );
};

const TextArea = ({ placeholder, value, onChange }) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      className="resize-none h-32 p-2 w-full outline-none text-base border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
      placeholder={placeholder}
      rows={1}
    />
  );
};

const CopilotConfigurations = () => {
  const [loading, setLoading] = useState(false);
  const [enableCopilot, setEnableCopilot] = useState(true);
  const [firstReminderInterval, setFirstReminderInterval] = useState(30);
  const [firstReminderMessage, setFirstReminderMessage] = useState(
    'It appears that there has been a pause in our conversation. Are we still connected? If you have any further questions or need assistance, please let me know.'
  );
  const [secondReminderInterval, setSecondReminderInterval] = useState(30);
  const [secondReminderMessage, setSecondReminderMessage] = useState(
    'Looks like you are not available/active at this moment, If you have any further questions or need assistance, please let me know.'
  );
  const [greetingsMessage, setGreetingsMessage] = useState(
    "Hello! How can I assist you today? If you're looking for information on blood tests or health packages, I'm here to help."
  );
  const [sessionLimit, setSessionLength] = useState(300);

  const handleEnableCopilot = () => {
    setEnableCopilot(!enableCopilot);
  };

  const handleFirstReminderInterval = (e) => {
    setFirstReminderInterval(e.target.value);
  };
  const handleFirstReminderMessage = (e) => {
    setFirstReminderMessage(e.target.value);
  };
  const handleSecondReminderInterval = (e) => {
    setSecondReminderInterval(e.target.value);
  };
  const handleSecondReminderMessage = (e) => {
    setSecondReminderMessage(e.target.value);
  };

  const handleGreetingsMessage = (e) => {
    setGreetingsMessage(e.target.value);
  };

  const handleSessionLength = (e) => {
    setSessionLength(e.target.value);
  };

  const handleSave = () => {
    const url = `${baseURL}/copilot/settings`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        copilotEnabled: enableCopilot,
        firstReminder: {
          interval: firstReminderInterval,
          message: firstReminderMessage,
        },
        secondReminder: {
          interval: secondReminderInterval,
          message: secondReminderMessage,
        },
        greetingMessage: greetingsMessage,
        sessionLength: {
          interval: sessionLimit,
        },
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setEnableCopilot(json?.copilotEnabled);
        setFirstReminderInterval(json?.firstReminder?.interval);
        setFirstReminderMessage(json?.firstReminder?.message);
        setSecondReminderInterval(json?.secondReminder?.interval);
        setSecondReminderMessage(json?.secondReminder?.message);
        setGreetingsMessage(json?.greetingMessage);
        setSessionLength(json?.sessionInterval?.interval);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const url = `${baseURL}/copilot/settings`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setEnableCopilot(json?.copilotEnabled);
        setFirstReminderInterval(json?.firstReminder?.interval);
        setFirstReminderMessage(json?.firstReminder?.message);
        setSecondReminderInterval(json?.secondReminder?.interval);
        setSecondReminderMessage(json?.secondReminder?.message);
        setGreetingsMessage(json?.greetingMessage);
        setSessionLength(json?.sessionLength?.interval);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="p-4  text-xl font-bold border-b border-b-disableColor ">
        <div>
          <Link to="/copilot">
            <span className=" text-primaryColor">Copilot</span>
          </Link>
          {' > '}
          <span className="text-highlightColor font-onest">Configure</span>
        </div>
      </div>
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className="p-4 flex flex-col gap-6">
          <div className="p-4 bg-white border border-neutral-200 rounded-md max-w-lg ">
            <div className=" text-lg font-semibold text-textPrimary">
              Enable Copilot
            </div>
            <div className="text-base font-medium mt-2">
              This will redirect all chats to agent if disabled
            </div>
            <div className="mt-4">
              <ToggleSwitch
                enabled={enableCopilot}
                onChange={handleEnableCopilot}
              />
            </div>
          </div>
          <div className="p-4 bg-white border border-neutral-200 rounded-md max-w-lg">
            <div className=" text-lg font-semibold text-textPrimary">
              Proactive reminders
            </div>
            <div className="text-base font-medium mt-2">
              Using proactive reminders, you can send reminders to customers
              only on active sessions
            </div>
            <div className="mt-4 flex-col flex gap-4">
              <div>
                <div className="mb-4">First reminder</div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex-[0.2]">After</div>
                    <div className="flex-[0.8]">
                      <TextInput
                        placeholder="after how many minutes"
                        value={firstReminderInterval}
                        onChange={handleFirstReminderInterval}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-[0.2]">Message</div>
                    <div className="flex-[0.8]">
                      <TextArea
                        placeholder="first reminder message"
                        value={firstReminderMessage}
                        onChange={handleFirstReminderMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-4">Second reminder</div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div className="flex-[0.2]">After</div>
                    <div className="flex-[0.8]">
                      <TextInput
                        placeholder="after how many minutes"
                        value={secondReminderInterval}
                        onChange={handleSecondReminderInterval}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-[0.2]">Message</div>
                    <div className="flex-[0.8]">
                      <TextArea
                        placeholder="first reminder message"
                        value={secondReminderMessage}
                        onChange={handleSecondReminderMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="p-4 bg-white border border-neutral-200 rounded-md max-w-lg">
            <div className=" text-lg font-semibold text-textPrimary">
              Messages
            </div>
            <div className="text-base font-medium mt-2">
              Make sure to keep your messages short and simple
            </div>
            <div className="mt-4 flex-col flex gap-4">
              <div className="flex items-center">
                <div className="flex-[0.2]">Greetings</div>
                <div className="flex-[0.8]">
                  <TextArea
                    placeholder="Hi, How can I help you?"
                    value={greetingsMessage}
                    onChange={handleGreetingsMessage}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="p-4 bg-white border border-neutral-200 rounded-md max-w-lg">
            <div className="mb-4">Session closes (in minutes)</div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                <div className="flex-[0.2]">After</div>
                <div className="flex-[0.8]">
                  <TextInput
                    placeholder="Session auto closes after this"
                    value={sessionLimit}
                    onChange={handleSessionLength}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="cursor-pointer w-fit py-4 px-6 bg-highlightColor rounded-md justify-end items-center gap-1 inline-flex"
            onClick={handleSave}
          >
            <div className="text-white text-sm font-bold font-['Onest'] capitalize leading-none ">
              Save
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopilotConfigurations;
