import baseAPI from './baseAPI';

export const getExcludedCategories = async (
  token,
  account,
  categoryName = null
) => {
  const resp = await baseAPI.get(
    `/pulse/category/configure?account=${account}&categoryName=${categoryName}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to fetch excluded categories');
  }
  return await resp.json();
};

export const updateExcludedCategories = async (
  token,
  account,
  categoryName = null,
  excluded
) => {
  const resp = await baseAPI.post(
    '/pulse/category/configure',
    {
      account,
      categoryName,
      excluded,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to update excluded categories');
  }
  return await resp.json();
};
