import AgentPerformanceLeaderboard from './AgentPerformanceLeaderBoard';
import TopPerformers from './TopPerformers';

const AgentPerformanceContainer = ({ agents }) => {
  return (
    <>
      <div className="text-darkGrey font-semibold text-lg rounded">
        Agent Performance
      </div>
      <div className="flex flex-col gap-4 bg-white">
        {agents instanceof Array && agents.length > 2 && (
          <TopPerformers agents={agents} />
        )}
        <AgentPerformanceLeaderboard agents={agents} />
      </div>
    </>
  );
};

export default AgentPerformanceContainer;
