import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  tickets: [],
  searchQuery: '',
  userId: '',
  noOfTickets: 10,
  conversation: [
    {
      from: 'copilot',
      message: 'Hello, How can I help you today?',
    },
  ],
  chatQuery: '',
  searchLoading: false,
  chatLoading: false,
  account: '',
};

export const useTicketQueryStore = create(
  persist(
    (set) => ({
      ...initialState,
      setAccount: (account) => set({ account }),
      setUserId: (userId) => set({ userId }),
      setTickets: (tickets) => set({ tickets }),
      setSearchQuery: (searchQuery) =>
        set({
          searchQuery,
          conversation: [
            {
              from: 'copilot',
              message: 'Hello, How can I help you today?',
            },
          ],
        }),
      setNoOfTickets: (noOfTickets) => set({ noOfTickets }),
      addCopilotMessage: (message) =>
        set((state) => ({
          conversation: [...state.conversation, { from: 'copilot', message }],
        })),
      addUserMessage: (message) =>
        set((state) => ({
          conversation: [...state.conversation, { from: 'you', message }],
        })),
      setChatQuery: (chatQuery) => set({ chatQuery }),
      setSearchLoading: (searchLoading) => set({ searchLoading }),
      setChatLoading: (chatLoading) => set({ chatLoading }),
      resetStore: () => set({ ...initialState }),
    }),
    {
      name: 'TicketQueryStore',
    }
  )
);
