import React, { useState } from 'react';

function Shimmer() {
  return <div className="w-full h-full bg-slate-200  animate-pulse"></div>;
}

function LazyLoadedImage({ src, alt, className }) {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={`${className}`}>
      {!loaded && <Shimmer />}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={` w-full h-full object-cover ${loaded ? 'block' : 'hidden'}`}
      />
    </div>
  );
}

export default LazyLoadedImage;
