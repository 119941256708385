import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  token: null,
  name: null,
  account: null,
  accountType: null,
  isLoggedIn: false,
};
const NO_OF_RECENT_DAYS_NOT_TO_SHOW = 2;

export const useAuthStore = create(
  persist(
    (set) => ({
      ...initialState,
      dateSelectionData: {
        selection: 'selection',
        range: {
          startDate: new Date(
            new Date().setDate(
              new Date().getDate() - NO_OF_RECENT_DAYS_NOT_TO_SHOW - 6
            )
          ),
          endDate: new Date(
            new Date().setDate(
              new Date().getDate() - NO_OF_RECENT_DAYS_NOT_TO_SHOW
            )
          ),
        },
      },
      updateAuth: (data) =>
        set({
          token: data.token,
          name: data.name,
          account: data.account,
          accountType: data.accountType,
          isLoggedIn: data.isAuthenticated,
        }),
      updateDateSelection: (data) => set({ dateSelectionData: data }),
      logout: async () => {
        set(initialState);
        localStorage.clear();
      },
      updateState: (key, value) =>
        set((state) => ({
          ...state,
          [key]: value,
        })),
    }),
    {
      name: 'auth',
    }
  )
);
