import Lightning from 'assets/icons/lighting.svg';
import { ArrowUpRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const RatingTooltipContent = () => (
  <div className="p-1 font-normal">
    <h3 className="font-semibold mb-1 text-sm">
      Normalized Rating is calculated based on the following weights:
    </h3>
    <ul className="list-disc pl-2 text-xs">
      <li>Total Tickets: 25%</li>
      <li>First Resolution Time: 25%</li>
      <li>Response Time: 10%</li>
      <li>Empathy: 10%</li>
      <li>Emotional Polarity: 5%</li>
      <li>Resolutionl Completeness: 12.5%</li>
      <li>Quality Support: 12.5%</li>
    </ul>
  </div>
);

const AgentPerformanceLeaderboard = ({ agents }) => {
  const navigate = useNavigate();

  const formatTimeToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    if (hours === 0) {
      return `${remainingMinutes}m`;
    }
    return `${hours}h ${remainingMinutes}m`;
  };

  const formattedData = agents.map((agent) => ({
    agent_id: agent.agent_id,
    name: agent.agent_name,
    kimScore: agent.kimScore,
    ticketsClosed: `${agent.totalTickets}`,
    ticketsChange: `N/A`,
    overall: `${(agent.averageCSAT.overall * 20).toFixed(2)}%`,
    empathy: `${(agent.averageCSAT.empathy * 20).toFixed(2)}%`,
    personalization: `${(agent.averageCSAT.qualitySupport * 20).toFixed(2)}%`,
    grammar: 'N/A', // Assuming no grammar data in the API response
    responsiveness: `${(agent.averageCSAT.agentResponsive * 20).toFixed(2)}%`,
    completeness: `${(agent.averageCSAT.resolutionalCompleteness * 20).toFixed(2)}%`,
    emotionalPolarity: `${(agent.averageCSAT.emotionalPolarity * 20).toFixed(2)}%`,
    productKnowledge: 'N/A', // Assuming no product knowledge data in the API response
    averageResponseTime: formatTimeToHoursAndMinutes(agent.averageResponseTime),
    averageFirstResolutionTime: formatTimeToHoursAndMinutes(
      agent.averageFirstResolutionTime
    ),
  }));

  return (
    <div className="p-4 bg-white rounded">
      <h2 className="text-darkGrey font-semibold text-lg mb-4">
        Agent Performance Leaderboard
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-sm">
          <thead>
            <tr>
              <th className="py-4 px-8 text-left font-semibold"></th>
              <th className="py-4 px-8 text-left font-semibold"></th>
              <th className="py-4 px-8 text-left font-semibold"></th>
              <th className="py-4 px-8 text-left font-semibold"></th>
              <th className="py-4 px-8 text-left font-semibold"></th>
            </tr>
            <tr className="border-t border-disableColor">
              <th className="py-4 px-8 text-left font-semibold border-l">
                Agent
              </th>
              <th className="py-4 px-8 text-left font-semibold bg-[#E8EAF6] bg-opacity-40">
                <span className="flex items-center justify-center w-full">
                  <img src={Lightning} alt="Rating" className="w-4 h-4 mr-2" />
                  Rating <span className="font-bold mx-1">/5</span>
                  <div className="ml-1">
                    <svg
                      className="w-4 h-4 text-gray-500 cursor-help"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      data-tooltip-id="rating-tooltip"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="9"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      />
                      <text
                        x="10"
                        y="15"
                        textAnchor="middle"
                        fill="currentColor"
                        fontSize="14"
                        fontWeight="bold"
                      >
                        i
                      </text>
                    </svg>
                  </div>
                </span>
              </th>
              <th className="py-4 px-8 text-left font-normal">
                Tickets Closed
              </th>
              <th className="py-4 px-8 text-left font-normal">FRT</th>
              <th className="py-4 px-8 text-left font-normal">
                Resolution Time
              </th>
              {/* <th className="py-4 px-8 text-center font-normal bg-[#E8EAF6] bg-opacity-40 border-l">
                Overall
              </th> */}
              <th className="py-4 px-8 text-center font-normal">Empathy</th>
              <th className="py-4 px-8 text-center font-normal">
                Personalization
              </th>
              <th className="py-4 px-8 text-center font-normal">
                Responsiveness
              </th>
              <th className="py-4 px-8 text-center font-normal">
                Completeness
              </th>
              <th className="py-4 px-8 text-center font-normal">
                Emotional Polarity
              </th>
            </tr>
          </thead>
          <tbody>
            {formattedData.map((agent, index) => (
              <tr
                key={index}
                className={`cursor-pointer hover:bg-[#E8EAF6] hover:bg-opacity-40 ${index % 2 === 0 ? 'bg-[#EEE] bg-opacity-40' : ''} ${index === formattedData.length - 1 ? 'border-b' : ''} `}
                onClick={() => navigate(`/pulse/agent/${agent.agent_id}`)}
              >
                <td className="py-4 px-8 border-l truncate flex items-center justify-between">
                  <span>{agent.name}</span>
                  <ArrowUpRight className="text-gray-500 w-4 h-4 pl-1" />
                </td>
                <td className="py-4 px-8 bg-[#E8EAF6] bg-opacity-40">
                  <span>{agent.kimScore}</span>
                </td>
                <td className="py-4 px-8 ">
                  <div className="flex items-center justify-between">
                    <span>{agent.ticketsClosed}</span>
                    {/* <span className={`flex items-center bg-lightGray p-[2px]`}>
                                            {agent.ticketsChange.startsWith('-') ? (
                                                <img src={DownRightArrow} alt="Decrease" className="w-4 h-4 mr-1" />
                                            ) : (
                                                <img src={UpRightArrow} alt="Increase" className="w-4 h-4 mr-1" />
                                            )}
                                            {agent.ticketsChange}
                                        </span> */}
                  </div>
                </td>
                <td className="py-4 px-8">
                  {agent.averageFirstResolutionTime}
                </td>
                <td className="py-4 px-8">{agent.averageResponseTime}</td>
                {/* <td className="py-4 px-8 text-center bg-[#E8EAF6] bg-opacity-40 border-l">
                  {agent.overall}
                </td> */}
                <td className="py-4 px-8 text-center">{agent.empathy}</td>
                <td className="py-4 px-8 text-center">
                  {agent.personalization}
                </td>
                <td className="py-4 px-8 text-center">
                  {agent.responsiveness}
                </td>
                <td className="py-4 px-8 text-center">{agent.completeness}</td>
                <td className="py-4 px-8 text-center">
                  {agent.emotionalPolarity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Tooltip id="rating-tooltip" place="top" className="max-w-xs">
        <RatingTooltipContent />
      </Tooltip>
    </div>
  );
};

export default AgentPerformanceLeaderboard;
