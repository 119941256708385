import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ZendeskLogo from '../assets/zendesk-3x.png';
import Checkbox from '../components/checkbox';

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-80 z-50">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};
const ZendeskConnect = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [apiToken, setAPItoken] = useState('');
  const [formData, setFormData] = useState({
    accountName: '',
    agentEmail: '',
    apiToken: '',
  });
  const [isZendeskConnected, setIsZendeskConnected] = useState(false);

  const navigate = useNavigate();
  const gorgiasDevServerUrl = process.env.REACT_APP_GORGIAS_DEV_SERVER_URL;

  const connectWithZendesk = async () => {
    setShowLoader(true);
    const resp = await axios.post(gorgiasDevServerUrl + `/zendesk/token`, {
      agentEmail: formData.agentEmail,
      apiToken: formData.apiToken,
      account: formData.accountName,
    });

    if (resp?.data) {
      setIsZendeskConnected(true);
      setShowLoader(false);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      accountName: localStorage.getItem('account'),
      agentEmail: localStorage.getItem('email'),
    }));
  }, []);

  const handleInputChange = (event) => {
    setAccountName(event.target.value);
  };

  const handleCheckboxChange = (value) => {
    setIsChecked(value);
  };

  useEffect(() => {
    if (isZendeskConnected) {
      navigate('/');
    }
  }, [isZendeskConnected]);

  return (
    <div className="bg-[#E8EAF6] w-full h-full flex flex-col justify-center items-center">
      <div className="bg-white mx-auto rounded-2xl">
        <div className="grid grid-cols-2 gap-16 justify-center items-center px-16 py-10">
          <img src={ZendeskLogo} className="max-w-[20rem]" />
          <div>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="mb-2 text-sm text-[#444746]">
                  Please enter your zendesk subdomain
                </div>
                <div className="flex items-center">
                  <div className="flex rounded-lg ring-1 ring-slate-300 bg-white mt-1">
                    <input
                      type="text"
                      placeholder="your account name"
                      className="rounded-lg p-4 w-full outline-none text-base cursor-not-allowed"
                      onChange={handleInputChange}
                      value={formData.accountName}
                      disabled
                    ></input>
                  </div>
                  <span className="text-sm text-[#444746] pl-2">
                    .zendesk.com
                  </span>
                </div>
              </div>
              <div className="flex flex-col mt-6">
                <div className="mb-2 text-sm text-[#444746]">
                  Please enter the agent email
                </div>
                <div className="flex rounded-lg ring-1 ring-slate-300 bg-white mt-1">
                  <input
                    type="email"
                    placeholder="your agent email"
                    className="rounded-lg p-4 w-full outline-none text-base cursor-not-allowed"
                    onChange={(event) => setAgentEmail(event.target.value)}
                    value={formData.agentEmail}
                    disabled
                  ></input>
                </div>
              </div>
              <div className="flex flex-col mt-6">
                <div className="mb-2 text-sm text-[#444746]">
                  Please enter the API token
                </div>
                <div className="flex rounded-lg ring-1 ring-slate-300 bg-white mt-1">
                  <input
                    type="text"
                    placeholder="enter the API token"
                    className="rounded-lg p-4 w-full outline-none text-base"
                    onChange={(event) =>
                      setFormData((prev) => ({
                        ...prev,
                        apiToken: event.target.value,
                      }))
                    }
                    value={formData.apiToken}
                  ></input>
                </div>
              </div>

              <div className="mt-6">
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              </div>
            </div>

            <button
              onClick={connectWithZendesk}
              className={`w-full text-white rounded-lg bg-[#181636] px-10 py-5 mt-10 ${formData.apiToken.length === 0 || !isChecked ? 'disabled:bg-gray-400 cursor-not-allowed' : ''}`}
              disabled={formData.apiToken.length === 0 || !isChecked}
            >
              Connect
            </button>
            {showError ? (
              <div className="mt-2 text-red-500">
                Something went wrong. Please try again
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {showLoader ? <Loader /> : null}
    </div>
  );
};

export default ZendeskConnect;
