import baseAPI from './baseAPI';

export const getAgentsPerformance = async (account, startDate, endDate, selectedCategoriesName = null) => {
    let query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
    if (selectedCategoriesName) {
        const categories = Array.isArray(selectedCategoriesName) ? selectedCategoriesName : [selectedCategoriesName];
        categories.forEach(category => query += `&category=${category}`);
    }
    const response = await baseAPI.get(`/agents/all${query}`);
    if (!response.ok) {
        throw new Error('Failed to fetch agents performance');
    }
    return await response.json();
};


export const getAgentPerformance = async (agentId, account, startDate, endDate) => {
    const query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
    const response = await baseAPI.get(`/agents/${agentId}${query}`);
    if (!response.ok) {
        throw new Error('Failed to fetch agent performance');
    }
    return await response.json();
};
export const getAgentCategoryData = async (agentId, account, startDate, endDate) => {
    const query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
    const response = await baseAPI.get(`/agents/category/${agentId}${query}`);
    if (!response.ok) {
        throw new Error('Failed to fetch agent category data');
    }
    return await response.json();
};
export const getSubcategoryData = async (agentId, account, start_date, end_date, category) => {
    const query = `?account=${account}&start_date=${start_date}&end_date=${end_date}&category=${category}`;
    const response = await baseAPI.get(`/agents/subcategory/${agentId}${query}`);
    if (!response.ok) {
        throw new Error('Failed to fetch subcategory data');
    }
    return await response.json();
};