import React from 'react';
import withOutsideClick from 'components/withOutsideClick';
import DropDownSVG from 'assets/icons/dropdown.svg';

const DropdownBase = ({ options = [], selectedOption, handleOptionChange, value = '_id', label = 'name', isOpen, toggleVisibility }) => {
  const renderSelectText = () => {
    const selected = options.find(option => option[value] == selectedOption);
    return selected ? selected[label] : 'Select an option';
  };

  return (
    <div className="relative inline-block w-64 text-sm font-inter bg-bgGray">
      <div
        onClick={toggleVisibility}
        className="appearance-none w-full border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline cursor-pointer text-sm"
      >
        {renderSelectText()}
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <img src={DropDownSVG} alt="dropdown" />
        </div>
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10 max-h-[50vh] overflow-y-auto">
          {options.map((option, index) => (
            <div
              key={index}
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                handleOptionChange(option[value]);
                toggleVisibility();
              }}
            >
              {option[label]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Dropdown = withOutsideClick(DropdownBase);

export default Dropdown;