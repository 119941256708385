import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../common/constant';
import { DownloadCSV } from '../../common/util';
// import LineChart from '../../components/LineChart';
import { getOverview } from '../../api/categoriesApi.js';
import { getRandomColor } from '../../common/util.js';
import DonutChart from '../../components/DonutChart.js';
import DateRange from '../../components/date';
import useComponentTiming from '../../components/useComponentTiming.js';
import { useAuthStore, useCategoryStore } from '../../state';
import MultiSelectDropdown from './components/MultiSelectDropdown/index.js';
import Overview from './components/Overview/index.js';
import Sidebar from './components/Sidebar.js';
import InsightModal from './components/insightModal.js';
import { Shimmer } from './components/shimmer.js';
const CategoryDetails = () => {
  useComponentTiming('View_category');
  const params = useParams();
  const navigate = useNavigate();
  const {
    categories,
    categoriesLoading,
    subCategories,
    fetchDataInCategoryDetail,
    updateState,
    allCategories,
    selectedCategoriesName,
    excludedCategories,
    overviewDetails,
    overviewLoading,
    categoriesNameList,
  } = useCategoryStore();
  const { dateSelectionData, token, account, accountType } = useAuthStore();

  const selectedCategories = useMemo(() => {
    return allCategories.filter((cat) =>
      selectedCategoriesName.includes(cat.name)
    );
  }, [allCategories, selectedCategoriesName]);

  const totalTicketsCount = useMemo(() => {
    return selectedCategories.reduce((acc, cat) => acc + cat.count, 0);
  }, [selectedCategories]);

  const chartData = useMemo(() => {
    return selectedCategories.map((cat, idx) => ({
      name: cat.name,
      value: Number(
        parseFloat((cat.count * 100) / totalTicketsCount)?.toFixed(2) ?? 0
      ),
      color: colors[idx] ?? getRandomColor(),
    }));
  }, [selectedCategories]);

  const chart = useRef(null);
  const posthog = usePostHog();

  //states for insight modal
  const [showInsightModal, setShowInsightModal] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDataInCategoryDetail(params.category);
    };
    fetchData();
  }, [dateSelectionData, account, token, params.category]);

  const handleOverviewChange = async (options, controller = null) => {
    try {
      const {
        range: { startDate, endDate },
      } = dateSelectionData;
      updateState('overviewLoading', true);
      const response = await getOverview(
        token,
        account,
        params.category,
        startDate,
        endDate,
        { signal: controller?.signal },
        options
      );
      updateState('overviewDetails', response[0]);
      updateState('overviewLoading', false);
    } catch (error) {
      if (error.name === 'AbortError') {
        // console.log('Fetch aborted due to new request or unmount');
        // Do not set loading false here because the fetch was intentionally aborted
      } else {
        console.log('Error in fetching overview:', error);
        updateState('overviewLoading', false); // Set loading false here for normal errors
      }
    }
  };

  return (
    <>
      {categoriesLoading ? (
        <Shimmer />
      ) : (
        <div className="flex h-full overflow-hidden bg-bgColor">
          <div className="flex-[0.10]">
            <Sidebar />
          </div>
          <div className="flex-[0.90] overflow-hidden">
            <div className="flex flex-col w-full font-onest min-w-full">
              <div className="flex flex-row w-full bg-white items-center justify-between px-5 py-4 gap-5 shrink-0 border-b border-b-disableColor border-t border-t-disableColor">
                <h3 className=" text-black">
                  <NavLink
                    to="/pulse"
                    className="no-underline text-black text-xl font-medium"
                  >
                    Pulse{' '}
                  </NavLink>
                  {`>`}{' '}
                  <span className="text-slateblue text-xl font-bold">{`${params.category}`}</span>
                </h3>
                <div className="flex flex-row items-center justify-center gap-4">
                  <MultiSelectDropdown
                    allOptions={categoriesNameList}
                    selectedOptions={selectedCategoriesName}
                    setSelectedOptions={(val) => {
                      updateState('selectedCategoriesName', val);
                    }}
                    isSubCategory={true}
                    handleOptionsChange={handleOverviewChange}
                  />
                  <DateRange pageName={`View_category_${params.category}`} />
                  <button
                    onClick={() => {
                      DownloadCSV(selectedCategories, 'SubCategory');
                      posthog.capture(
                        `View_category-${params.category}-download`,
                        {
                          page_title: `${params.category}`,
                        }
                      );
                    }}
                    className="cursor-pointer [border:none] px-4 py-2 bg-slateblue rounded-full flex flex-row items-center justify-center hover:bg-royalblue-200"
                  >
                    <img
                      className="h-4 w-4  overflow-hidden shrink-0"
                      alt=""
                      src="/fi-3502477.svg"
                    />
                    <b className=" text-sm capitalize inline-block font-onest text-white text-left">
                      Download
                    </b>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-5 h-contentHeight overflow-auto">
              {/* OverView */}
              <Overview
                categoryName={params.category}
                overviewDetails={overviewDetails}
                excludedCategories={excludedCategories}
                categoriesNameList={allCategories.map((cat) => cat.name)}
                isLoading={overviewLoading}
              />
              <div className="flex mb-3">
                <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap min-w-full mt-[15px]">
                  <div className="flex flex-col items-start justify-start pt-[4.5px] px-0 pb-0">
                    <h3 className="m-0  text-inherit leading-[20px] capitalize font-semibold font-inherit">
                      Subcategories overview
                    </h3>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 text-sm text-gray font-onest">
                <div
                  ref={chart}
                  className="rounded-sm bg-white col-span-2 flex items-center"
                >
                  <DonutChart
                    outerRadius={120}
                    innerRadius={60}
                    total={totalTicketsCount}
                    data={chartData}
                  />
                </div>
                <div className="col-span-3 flex flex-col rounded-sm bg-white  p-5 gap-1 max-w-full text-xs text-darkGrey h-[50vh] overflow-y-auto">
                  <div
                    className={`grid gap-2 grid-cols-6 justify-center font-onest text-sm font-semibold text-black`}
                  >
                    <div className="capitalize font-semibold col-span-2">
                      Sub-Category
                    </div>
                    <div className="capitalize font-semibold text-end col-span-1">{`# Tickets `}</div>

                    <div className="capitalize font-semibold text-end col-span-1">
                      AI CSAT
                    </div>

                    <div className="capitalize text-center font-semibold col-span-2">
                      Actions
                    </div>
                  </div>
                  <div className=" flex flex-col justify-center divide-y divide-disableColor">
                    {selectedCategories.length === 0 && (
                      <div className="flex flex-row items-center justify-center h-40">
                        <div className="text-darkGrey font-semibold text-lg">
                          No data available for the selected dates or category
                        </div>
                      </div>
                    )}
                    {selectedCategories.map((category, index) => (
                      <div
                        className={`grid grid-cols-6 gap-2 text-darkGrey py-4`}
                        key={category.name}
                      >
                        <div className="flex flex-row items-center gap-2 col-span-2">
                          <div
                            className="w-3 h-3  rounded-[50%]"
                            style={{
                              backgroundColor: chartData.find(
                                (cat) => cat.name === category.name
                              ).color,
                            }}
                          />
                          <div
                            className="relative capitalize font-medium text-xs truncate"
                            title={`${category.name}`}
                          >
                            {category.name}
                          </div>
                        </div>
                        <div className="flex flex-row text-center items-center justify-end relative lowercase font-medium  text-xs col-span-1">
                          {category.count}
                        </div>

                        <div className="flex justify-end font-medium">
                          {/* {(
                            (category?.csat_score?.toFixed(2) / 5) *
                            100
                          )?.toFixed(2)}
                          % */}
                          {category?.csat_score?.toFixed(2)}
                        </div>
                        <div className="flex flex-col items-center justify-center text-3xs text-slateblue col-span-2">
                          <div className="flex flex-row gap-5">
                            <div className="flex flex-col items-start justify-start">
                              <div
                                className="flex flex-row items-start justify-start gap-[2px] cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    `/pulse/${encodeURIComponent(params.category)}/${encodeURIComponent(category.name)}/tickets`
                                  );

                                  posthog.capture('View_category-view_ticket', {
                                    page_title: `${params.category}`,
                                    category_name: `${category.name}`,
                                  });
                                }}
                              >
                                <div className="relative leading-[110%] capitalize font-semibold min-w-[72px] text-xs">
                                  View tickets
                                </div>
                                <div className="flex flex-col items-start justify-start ml-1">
                                  <img
                                    className="w-2.5 h-2.5 relative overflow-hidden shrink-0"
                                    loading="lazy"
                                    alt=""
                                    src="/fi-152356.svg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="flex flex-row items-start justify-start gap-[2px] cursor-pointer"
                              onClick={() => {
                                setSelectedSubcategory(category.name);
                                setShowInsightModal(!showInsightModal);
                              }}
                            >
                              <div className="relative leading-[110%] capitalize font-semibold min-w-[36px] text-xs">
                                Why?
                              </div>
                              <div className="flex flex-col items-start justify-start ml-1">
                                <img
                                  className="w-2.5 h-2.5 relative overflow-hidden shrink-0"
                                  alt=""
                                  src="/fi-152356.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showInsightModal && (
            <InsightModal
              onClose={() => setShowInsightModal(false)}
              dateSelectionData={dateSelectionData}
              account={account}
              category={params.category}
              subcategory={selectedSubcategory}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CategoryDetails;
