import React from 'react';

const TopBar = ({ children }) => {
  return (
    <div className="bg-gray-50 px-8 py-4 shadow flex justify-between items-center font-onest">
      {children}
    </div>
  );
};

export default TopBar;
