import { marked } from 'marked';
import React, { useEffect, useRef, useState } from 'react';
import { List, Person } from 'react-bootstrap-icons';
import PulseLoader from 'react-spinners/ClipLoader';
import KimLogo from '../assets/kim-white.svg';

const CopilotPlayground = (props) => {
  const { client } = props;
  const WS_SERVER =
    client === 'valeo'
      ? process.env.REACT_APP_VALEO_SERVER_URL
      : process.env.REACT_APP_WS_SERVER_URL;

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [ws, setWs] = useState(null);
  const [showConfig, setShowConfig] = useState(
    client === 'mensa' ? true : false
  );
  const [customerEmail, setCustomerEmail] = useState(
    client === 'mensa' ? 'sharmasuraj1178@gmail.com' : ''
  );
  const [connection, setConnection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [tab, setTab] = useState('copilot');
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target) && ws) {
        setShowConfig(false);
      }
    }

    // Add when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Return function to be called when it unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;
    if (client === 'mensa') {
      if (selectedOrderId) {
        ws.send(
          JSON.stringify({
            uid: connection.uid,
            content: {
              type: 'ORDER_ASSISTANCE',
              message,
            },
          })
        );
      } else {
        ws.send(
          JSON.stringify({
            uid: connection.uid,
            content: {
              type: 'ORDER_SELECTION',
              message,
            },
          })
        );
      }
    }

    if (client === 'valeo') {
      ws.send(
        JSON.stringify({
          uid: connection.uid,
          content: {
            message,
          },
        })
      );
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, sender: 'self' },
    ]);
    setMessage('');
    setCurrentMessage('');
  };

  const startCopilot = () => {
    let webSocket = '';
    if (client === 'valeo') {
      webSocket = new WebSocket(`${WS_SERVER}/valeo`); // Your WebSocket URL
    }
    if (client === 'mensa') {
      webSocket = new WebSocket(`${WS_SERVER}/ws?email=${customerEmail}`); // Your WebSocket URL
    }

    setWs(webSocket);

    webSocket.onerror = function () {
      console.log('WebSocket error');
    };
    webSocket.onopen = function (event) {
      console.log('WebSocket connection established');
      setShowModal(true);
      setShowConfig(false);
      setMessages([]);
      setShowModal(true);
    };
    webSocket.onclose = function () {
      console.log('WebSocket connection closed');
    };
    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { type, message } = data;
      if (type === 'START') {
        const { customer, uid } = JSON.parse(message);
        setConnection({ customer, uid });
      }

      if (type === 'GREETINGS') {
        const { msg: content, orderId } = message;
        const startIndex = content.indexOf('```html') + 7; // Skip past the "```json " part
        const endIndex = content.lastIndexOf('```');
        const html = content.substring(startIndex, endIndex).trim();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: html, sender: 'bot' },
        ]);
        if (orderId !== -1) {
          setSelectedOrderId(orderId);
        }
        setShowModal(false);
      }

      if (type === 'ERROR') {
        const content = message;
        setShowModal(false);
        setShowConfig(true);
        setError(content);
      }
      if (type === 'MESSAGE') {
        const content = message;
        setCurrentMessage('');
        setShowModal(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: content, sender: 'bot' },
        ]);
        // if (reason !== "stop") {
        //   if (content) {
        //     setCurrentMessage((prev) => prev + content);
        //   }
        // } else {
        //   setCurrentMessage((prevCurrentMessage) => {
        //     setMessages((prevMessages) => [
        //       ...prevMessages,
        //       { text: prevCurrentMessage, sender: "other" },
        //     ]);
        //     return ""; // Reset currentMessage to clear the UI
        //   });
        // }
      }

      if (client === 'mensa') {
        if (type === 'VALIDATE_ORDER') {
          const { orderId, isValid, msg } = JSON.parse(message);

          if (isValid) {
            setSelectedOrderId(orderId);
          }
          setCurrentMessage('');
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: msg, sender: 'bot' },
          ]);
        }

        if (type === 'SINGLE_ORDER') {
          const orderId = data.orderId;
          setSelectedOrderId(orderId);
          setCurrentMessage('');
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: message, sender: 'bot' },
          ]);
          setShowModal(false);
        }

        if (type === 'INCOMPLETE_QUERY') {
          const message = data.message;
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: message, sender: 'bot' },
          ]);
        }
      }

      if (type === 'SYSTEM_MESSAGE') {
        setCurrentMessage('typing...');
      }
    };

    return () => {
      webSocket.close();
    };
  };

  return (
    <div className="bg-[#E8EAF6] h-screen flex  font-inter bg-cover">
      <div className="bg-[#181B4C] shadow-md px-4 w-[160px]">
        <div className=" flex  flex-col py-4 font-inter">
          <div className="text-xl">
            <img src={KimLogo} className="max-w-[4rem]" />
          </div>
          <div className=" text-white flex flex-col relative mt-[3rem]">
            <div
              className={`text-md font-semibold p-2 cursor-not-allowed border-b-1 border-transparent  ${
                tab === 'voc' ? 'border-white text-white' : 'text-[#d8d8d8]'
              }`}
            >
              Pulse
            </div>
            <div
              className={`text-md font-semibold p-2 cursor-pointer ${
                tab === 'copilot'
                  ? 'border-white text-white border-b text-lg font-bold'
                  : 'text-[#d8d8d8]'
              }`}
              onClick={() => setTab('copilot')}
            >
              Copilot
            </div>
            {/* {<div
            className={`text-md font-semibold p-4 cursor-pointer border-b-2 border-transparent  ${
              tab === "settings" ? "border-white text-white" : "text-[#d8d8d8]"
            }`}
            onClick={() => setTab("voc")}
          >
            Settings
          </div>} */}
          </div>
          <div className="text-white text-md absolute bottom-4">
            <div className="flex items-center">
              <Person />
              <p className=" font-medium ml-2">
                {localStorage.getItem('name')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="min-h-screen bg-[#f2f2f2] font-inter text-textPrimary flex-1 overflow-y-auto"
        style={{
          backgroundImage:
            'radial-gradient(circle, rgb(203, 213, 225) 1px, transparent 1px)',
          backgroundSize: '20px 20px',
        }}
      >
        <div className="relative min-h-screen">
          {showConfig ? (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50">
              {/* The modal content container */}
              <div className="font-semibold text-lg text-textPrimary">
                Before starting the copilot we need know your emailId.
              </div>
              <div
                className="bg-white p-8 absolute right-0 w-80 z-10 rounded-l-lg shadow-md h-full"
                ref={modalRef}
              >
                {/* <div
                  className="p-2 cursor-pointer -left-5 absolute z-20 top-0"
                  onClick={() => setShowConfig(false)}
                >
                  <XCircleFill color="black" size={24} />
                </div> */}
                <div className="grid grid-col-1 gap-4">
                  <div className="gap-2 grid">
                    <div className=" font-inter text-md font-semibold">
                      Customer email
                    </div>
                    <input
                      type="email"
                      value={customerEmail}
                      onChange={(e) => {
                        setCustomerEmail(e.target.value);
                        setError('');
                      }}
                      className=" rounded-lg p-4 w-full outline-none text-base border border-slate-300 mt-1"
                      placeholder="Enter you customer email"
                    />
                  </div>
                  <div className="gap-2 grid">
                    <div className=" font-inter text-md font-semibold">
                      Copilot rule
                    </div>
                    <div className="border p-4 bg-gray-100 rounded-lg ">
                      {' '}
                      Delivery issues
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!customerEmail}
                  className={`mt-8 px-4 py-2 disabled:bg-gray-500 disabled:cursor-not-allowed bg-ascentColor text-white rounded-md hover:bg-darkerAscentColor focus:outline-none`}
                  onClick={startCopilot}
                >
                  Start Copilot
                </button>
                {error && <div className="text-red-500 mt-4">{error}</div>}
              </div>
            </div>
          ) : (
            <div
              className="absolute top-4 right-4 cursor-pointer z-10 bg-white p-2 rounded-full shadow-md"
              onClick={() => setShowConfig(true)}
            >
              <List />
            </div>
          )}
          {showModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50 flex-col">
              <div className=" font-inter text-textPrimary/80 font-semibold">
                Setting up the environment
              </div>
              <div className="mt-4">
                <PulseLoader color="#085454" loading={true} size={40} />
              </div>
            </div>
          )}
          <div className="px-4 max-w-6xl mx-auto pt-12">
            <div className="w-full pt-8 pb-24 flex flex-col font-inter">
              {messages.map((msg, idx) =>
                msg.sender === 'self' ? (
                  <div
                    key={idx}
                    className=" ring-slate-400 w-3/4 self-start border p-4 rounded-lg my-4 bg-white"
                  >
                    {msg.text}
                  </div>
                ) : (
                  <div
                    key={idx}
                    className=" ring-slate-400 w-3/4 self-end border p-4 mr-10 rounded-lg bg-ascentColor text-white"
                    // dangerouslySetInnerHTML={{ __html: msg.text }}
                    dangerouslySetInnerHTML={{ __html: marked(msg.text) }}
                  />
                )
              )}
              {currentMessage && (
                <div className="text-right p-4 italic animate-pulse mr-10">
                  {<div>{currentMessage}</div>}
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <div className="fixed bottom-4 w-1/2">
                <form onSubmit={sendMessage} className="flex w-full">
                  <div className="flex rounded-2xl ring-1 ring-slate-400 bg-white mt-1 flex-1 shadow-2xl">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className=" rounded-2xl p-4 w-full outline-none text-base"
                      placeholder="Type your message here..."
                    />

                    <button
                      type="submit"
                      className="ml-4 px-4 py-2 bg-ascentColor text-white rounded-md hover:bg-darkerAscentColor focus:outline-none"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopilotPlayground;
