import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  getAllAgentsApi,
  getAgentMonthlyLog,
  getImages,
  displayImageUrlsApi,
  createNewLogApi
} from '../api/timeTrackerApi';
import { useAuthStore } from './auth.js';
import { extractDateFromFilename } from '../utils/helpers.js';
import { toast } from 'react-toastify';

const initialState = {
  agents: [],
  selectedAgent: null,
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
  monthlyLogs: [], // all agent logs for the selected month
  dailyLogs: [], // all agent logs sorted by day
  dailyImages: [], // all images for the selected day
  countOfImages: 0,
  imagePage: 1,
  displayImages: [], // images to display with s3 url and presigned url, max of 20 images in one view
  isAgentLoading: false,
  isMonthlyLogsLoading: false,
  isDailyImagesLoading: false,
  isDisplayImagesLoading: false,
};

export const MAX_IMAGES_PER_PAGE = 20;

const processData = (logs) => {
  // Initialize an object to hold the summed durations per day
  const durationByDay = {};

  logs.forEach((log) => {
    // Extract day of the month from startTime
    const day = new Date(log.startTime).getDate();
    // console.log(`Day: ${day} ${log.duration}`);

    // If there's no entry for this day, initialize it
    if (!durationByDay[day]) {
      durationByDay[day] = 0;
    }

    // Add duration of this log to the total for the day
    if (!isNaN(parseFloat(log.duration)))
      durationByDay[day] += parseFloat(log.duration) * 1000 * 60;
  });

  // Convert the object into an array of { day, duration } objects
  return Object.entries(durationByDay).map(([day, duration]) => ({
    day: parseInt(day),
    duration,
  }));
};

export const useTimeTrackerStore = create(
  persist(
    (set) => ({
      ...initialState,
      resetStore: () => set({ ...initialState }),
      updateState: (key, value) => set((state) => ({ ...state, [key]: value })),
      resetImages: () =>
        set((state) => ({
          ...state,
          displayImages: [],
          imagePage: 1,
          countOfImages: 0,
          dailyImages: [],
        })),
      fetchAllAgents: async () => {
        const { token } = useAuthStore.getState();
        try {
          set((state) => ({ ...state, isAgentLoading: true }));
          const resp = await getAllAgentsApi(token);
          set((state) => ({ ...state, agents: resp.data ?? [] }));
        } catch (error) {
          console.error('Error fetching agents:', error);
        } finally {
          set((state) => ({ ...state, isAgentLoading: false }));
        }
      },
      fetchMonthlyLogs: async () => {
        const { selectedAgent, month, year } = useTimeTrackerStore.getState();
        const { token, account } = useAuthStore.getState();
        try {
          set((state) => ({ ...state, isMonthlyLogsLoading: true }));
          // Fetch logs
          const resp = await getAgentMonthlyLog(
            selectedAgent,
            account,
            month,
            year,
            token
          );

          const processedLogs = processData(resp.data);

          set((state) => ({ ...state, monthlyLogs: resp.data ?? [] }));
          set((state) => ({ ...state, dailyLogs: processedLogs ?? [] }));
        } catch (error) {
          console.error('Error fetching logs:', error);
        } finally {
          set((state) => ({ ...state, isMonthlyLogsLoading: false }));
        }
      },
      fetchDailyImage: async (date) => {
        const { selectedAgent } = useTimeTrackerStore.getState();
        const { token, account } = useAuthStore.getState();
        try {
          set((state) => ({ ...state, isDailyImagesLoading: true }));
          const resp = await getImages(selectedAgent, account, date, token);
          // console.log('Daily Image:', resp.data);
          set((state) => ({
            ...state,
            dailyImages: resp.data ?? [],
            countOfImages: resp.data?.length ?? 0,
          }));
        } catch (error) {
          console.error('Error fetching daily image:', error);
        } finally {
          set((state) => ({ ...state, isDailyImagesLoading: false }));
        }
      },
      fetchDisplayImagesForPage: async (page) => {
        const { dailyImages, countOfImages } = useTimeTrackerStore.getState();
        const { token } = useAuthStore.getState();
        try {
          set((state) => ({ ...state, isDisplayImagesLoading: true }));
          const start = (page - 1) * MAX_IMAGES_PER_PAGE;
          const end = Math.min(page * MAX_IMAGES_PER_PAGE, countOfImages);
          // if (displayImages.length >= countOfImages) {
          //   console.log('All images are already displayed');
          //   return;
          // }
          const images = dailyImages.slice(start, end);
          const resp = await displayImageUrlsApi(images, token);
          const imageObj = images.map((image, idx) => ({
            imageUrl: resp.data.urls[idx],
            date: extractDateFromFilename(image) ?? new Date(),
          }));

          set((state) => ({
            ...state,
            displayImages: imageObj,
          }));
        } catch (error) {
          console.error('Error fetching display images:', error);
        } finally {
          set((state) => ({ ...state, isDisplayImagesLoading: false }));
        }
      },
      createNewLog: async ({ date, month, year, duration }) => {
        try {
          const { token, account } = useAuthStore.getState();
          const { selectedAgent } = useTimeTrackerStore.getState();
          const resp = await createNewLogApi({ date, month, year, duration, agentId: selectedAgent, account, token });
          toast.success('Log created successfully');
          await useTimeTrackerStore.getState().fetchMonthlyLogs();
        } catch (error) {
          console.error('Error creating new log:', error);
          toast.error(error.response?.data?.message || 'Failed to create new log');
        }
      }
    }),
    {
      name: 'TimeTrackerStore',
    }
  )
);