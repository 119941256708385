import React, { useState } from 'react';
import MultiSelectDropdown from './multiSelectDropdown';
import DateRange from 'components/date';
import SelectDropDown from 'components/selectDropdown';
import { exportToCSV } from 'utils/helpers';
import { useAuthStore } from 'state/auth';

const TopBarContent = ({ allOptions, selectedOptions, handleOptionsChange, agents, selectedAgent, handleAgentChange, selectedCategories }) => {
    const [isExporting, setIsExporting] = useState(false);
    const { account, dateSelectionData } = useAuthStore();
    const { range: { startDate, endDate } } = dateSelectionData;

    const handleExport = async () => {
        setIsExporting(true);
        try {
            const agentName = agents.find(agent => agent.agent_id == selectedAgent)?.agent_name ?? '';
            await exportToCSV(selectedCategories, selectedAgent, account, startDate, endDate, agentName);
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div className="flex items-center justify-between rounded-lg w-full">
            <div className="flex items-center gap-4">
                <div className="text-xl font-bold">Agent Performance</div>
                <SelectDropDown
                    options={agents}
                    selectedOption={selectedAgent}
                    handleOptionChange={handleAgentChange}
                    value="agent_id"
                    label="agent_name"
                />
            </div>
            <div className="flex items-center gap-4">
                <MultiSelectDropdown
                    allOptions={allOptions}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={handleOptionsChange}
                    placeholder="Select Categories"
                    optionsText="Categories"
                    optionsValue="Category"
                />
                <DateRange pageName="Page_View" />
                <button
                    onClick={handleExport}
                    disabled={isExporting}
                    className="cursor-pointer border-none px-4 py-2 bg-slateblue rounded-full flex items-center justify-center hover:bg-royalblue-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isExporting ? (
                        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
                    ) : (
                        <img className="h-4 w-4" alt="Download" src="/fi-3502477.svg" />
                    )}
                    <b className="text-sm text-white ml-2">
                        {isExporting ? 'Exporting...' : 'Download'}
                    </b>
                </button>
            </div>
        </div>
    );
};

export default TopBarContent;