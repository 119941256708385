import { useEffect, useState } from 'react';
import { labelColor } from '../../../../common/util';

const EstimatedCsat = ({ ticketDetails }) => {
  const [expandedSections, setExpandedSections] = useState(null);

  const toggleSection = (key) => {
    if (expandedSections === key) {
      setExpandedSections(null);
      return;
    }
    setExpandedSections(key);
  };
  useEffect(() => {
    //open first section by default
    if (ticketDetails?.csat_data) {
      const firstKey = Object.keys(ticketDetails.csat_data)?.[0];
      setExpandedSections(firstKey);
    }
  }, [ticketDetails?.csat_data]);

  return (
    <div className="rounded-sm bg-white overflow-hidden flex flex-col items-start justify-start p-3.5 border border-gray-200">
      <div
        className="grid w-full text-xs gap-y-4 border-b border-gray-200"
        style={{ gridTemplateColumns: '2fr 1fr' }}
      >
        <div className="flex flex-row text-sm font-semibold capitalize pr-5 pb-3 w-full">
          <div className="flex">AI CSAT</div>
          <div
            className={`flex p-1 ml-1 text-xs font-medium capitalize rounded-sm ${labelColor(ticketDetails?.csat_score)}`}
          >
            {ticketDetails?.csat_score}/5
          </div>
        </div>
      </div>
      <div className="text-xs w-full divide-y-[1px] divide-gray-200">
        {ticketDetails?.csat_data &&
          Object.entries(ticketDetails?.csat_data)
            ?.filter(([key, { score }]) => key !== 'Bonus' || score !== 0)
            ?.map(([key, { score, reason }]) => (
              <div key={key}>
                <button
                  type="button"
                  className="w-full py-3 flex justify-between items-center text-left"
                  onClick={() => toggleSection(key)}
                >
                  <span
                    className={`flex mr-1 items-center gap-2 ${expandedSections === key ? 'rotate-180' : ''}`}
                  >
                    <svg
                      className="w-4 h-4 transform transition-transform duration-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      role="img"
                      aria-label="Toggle"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </span>
                  <span className="capitalize font-medium flex-grow">
                    {key}
                    <span
                      className={`ml-1 p-1 text-xs font-medium capitalize rounded-sm ${labelColor(score)}`}
                    >
                      {score}/5
                    </span>
                  </span>
                </button>
                {expandedSections === key && (
                  <div className="mb-3 mx-2 p-2 bg-[#f5f5f5] rounded">
                    {reason}
                  </div>
                )}
              </div>
            ))}
      </div>
    </div>
  );
};

export default EstimatedCsat;
