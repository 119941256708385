import Check from '../../assets/check.png';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const IntegrationSuccess = () => {
  const { width, height } = useWindowSize();
  return (
    <div className="bg-[#E8EAF6] w-full h-full flex justify-center items-center">
      <Confetti width={width} height={height} />
      <div className="bg-white mx-auto rounded-lg flex flex-col justify-center items-center px-20 py-20">
        <img src={Check} className=" max-w-[120px]" />
        <div className=" font-semibold text-green-600 text-2xl mt-3">
          Thank you for trusting us
        </div>
        <div className=" font-medium text-lg mt-2">
          We will update you as soon we are ready for you
        </div>
      </div>
    </div>
  );
};

export default IntegrationSuccess;
