import axios from 'axios';
import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

const InsightModal = ({ onClose, subcategory, dateSelectionData, account }) => {
  const [query, setQuery] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const AGENT_BASE_URL = process.env.REACT_APP_AGENT_SERVER;

  const popularQuestions = [
    'What are the top 3 reasons for this issue?',
    'What can we do to improve this operation?',
    'What is the top reason for negative CSAT?',
    'What is the top reason for positive CSAT?',
  ];

  const handleSearch = async (searchQuery = query, question = '') => {
    setIsLoading(true);
    setSelectedQuestion(question);
    try {
      const response = await axios.post(
        `${AGENT_BASE_URL}/query/generate_report`,
        {
          user_query: searchQuery,
          subcategory: subcategory,
          start_date: format(
            new Date(dateSelectionData?.range?.startDate),
            'yyyy-MM-dd'
          ),
          end_date: format(
            new Date(dateSelectionData?.range?.endDate),
            'yyyy-MM-dd'
          ),
          client_name: account,
        }
      );
      setResults(response.data);
      setShowSearch(false);
    } catch (error) {
      console.error('Error fetching insights:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg min-w-xl max-w-xl">
        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-col">
            <h2 className="text-xl font-bold">
              Discover insights{' '}
              <span className="text-3xs font-medium text-neutral-500">
                Beta
              </span>
            </h2>
            <h3 className="text-sm font-semibold">
              <span className="text-neutral-500">Subcategory:</span>{' '}
              {subcategory}
            </h3>
          </div>

          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        {isLoading ? (
          <>
            <div className="w-full p-2 border border-gray-300 rounded mb-4">
              {query || selectedQuestion}
            </div>
            <div className="loader">Loading...</div>
          </>
        ) : (
          <>
            {showSearch ? (
              <>
                <input
                  type="text"
                  placeholder="Enter search term"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                <button
                  onClick={() => handleSearch(query)}
                  className="w-full bg-slateblue text-white p-2 rounded hover:bg-royalblue-200 font-semibold cursor-pointer"
                  disabled={isLoading || !query}
                >
                  {isLoading ? 'Loading...' : 'Search'}
                </button>
                <div className="mt-4">
                  <h3 className="text-lg font-semibold">Popular Questions:</h3>
                  <div className="grid grid-cols-1 gap-2">
                    {popularQuestions.map((question, index) => (
                      <div
                        key={index}
                        className="cursor-pointer text-slateblue hover:underline p-2 border border-gray-300 rounded"
                        onClick={() => handleSearch(question, question)}
                      >
                        {question}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {results && (
                  <div className="mt-4 max-h-[300px] overflow-y-auto">
                    <p className="text-sm font-medium text-neutral-500 mb-2">
                      Query: {query || selectedQuestion}
                    </p>

                    <h3 className="text-lg font-semibold">Insights</h3>
                    <ReactMarkdown>{results.report}</ReactMarkdown>
                  </div>
                )}
                <button
                  onClick={() => setShowSearch(true)}
                  className="w-full bg-slateblue text-white p-2 rounded hover:bg-royalblue-200 font-semibold cursor-pointer mt-4"
                >
                  Ask Another Question
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InsightModal;
