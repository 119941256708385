import React from 'react';
import { format } from 'date-fns';
// import LazyLoadedImage from '../LazyLoadedImage';
import LazyLoadedImage from '../../../../../../components/LazyLoadedImage';

const ImageDateDisplay = ({ date, imageUrl, onClick }) => {
  return (
    <div
      className="flex flex-col items-center justify-center"
      onClick={onClick}
    >
      <div className="flex items-center justify-between w-full text-textGray">
        <div className=" text-sm ">{format(date, 'yyyy-MM-dd')}</div>
        <div className="text-sm ">{format(date, 'hh:mm a')}</div>
      </div>
      <LazyLoadedImage src={imageUrl} alt="image" className="w-full h-[15vh]" />
    </div>
  );
};

export default ImageDateDisplay;
