import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { useTicketQueryStore } from '../state/useTicketQuery';
import ChatBar from './components/ChatBar';
import ConversationDisplay from './components/ConversationDisplay';
import SearchBar from './components/SearchBar';
import SideBar from './components/SideBar';
import TicketDisplay from './components/TicketDisplay';

const TicketQuery = () => {
  const { tickets, setUserId, resetStore, searchLoading, setAccount } =
    useTicketQueryStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const account = searchParams.get('account');

  useEffect(() => {
    const randomUserId = Math.floor(Math.random() * 1000000000).toString();
    setUserId(randomUserId);
    setAccount(account);
    return () => {
      setUserId('');
      resetStore();
    };
  }, []);

  return (
    <div className="bg-[#E8EAF6] h-screen flex  font-inter bg-cover">
      <SideBar />
      <div
        className="min-h-screen bg-[#f2f2f2] font-inter text-textPrimary flex-1 overflow-y-auto"
        style={{
          backgroundImage:
            'radial-gradient(circle, rgb(203, 213, 225) 1px, transparent 1px)',
          backgroundSize: '20px 20px',
        }}
      >
        <div className="relative min-h-screen">
          <div className="flex flex-col justify-center items-center b-10">
            <h1 className="text-center text-2xl font-bold text-textPrimary py-4">
              {' '}
              Find Tickets
            </h1>

            <SearchBar />
            {searchLoading && <BarLoader color="#000" className="m-5 w-full" />}
            {tickets?.length > 0 && !searchLoading && (
              <>
                <TicketDisplay />
                <h1 className="text-center text-2xl font-bold text-textPrimary py-4">
                  {' '}
                  ASK Query
                </h1>
                <ConversationDisplay />
                <ChatBar />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketQuery;
