import { useEffect, useState } from 'react';

const TopPerformers = ({ categories }) => {
  const [topPerformers, setTopPerformers] = useState([]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const bestRating = Math.max(
        ...categories.map((a) => a.averageCSAT.overall)
      );
      const worstRating = Math.min(
        ...categories.map((a) => a.averageCSAT.overall)
      );

      const metrics = [
        'empathy',
        'qualitySupport',
        'agentResponsive',
        'resolutionalCompleteness',
        'emotionalPolarity',
      ];

      const getAverageMetric = (metric) => {
        const sum = categories.reduce(
          (acc, category) => acc + category.averageCSAT[metric],
          0
        );
        return sum / categories.length;
      };

      const metricAverages = metrics.map((metric) => ({
        name: metric,
        average: getAverageMetric(metric),
      }));

      const strength = metricAverages.reduce((max, curr) =>
        curr.average > max.average ? curr : max
      );
      const weakness = metricAverages.reduce((min, curr) =>
        curr.average < min.average ? curr : min
      );

      const getDisplayName = (name) => {
        const nameMap = {
          empathy: 'Empathy',
          qualitySupport: 'Personalization',
          agentResponsive: 'Responsiveness',
          resolutionalCompleteness: 'Completeness',
          emotionalPolarity: 'Emotional Polarity',
        };
        return nameMap[name] || name.charAt(0).toUpperCase() + name.slice(1);
      };

      setTopPerformers([
        {
          category: 'Best Rating',
          name: categories.find((a) => a.averageCSAT.overall === bestRating)
            .agent_name,
          score: `${bestRating.toFixed(1)}/5`,
          average: `Average ${(categories.reduce((sum, a) => sum + a.averageCSAT.overall, 0) / categories.length).toFixed(2)}/5`,
          bgColor: 'bg-pink-100',
          link: '#',
        },
        {
          category: 'Worst Rating',
          name: categories.find((a) => a.averageCSAT.overall === worstRating)
            .agent_name,
          score: `${worstRating.toFixed(1)}/5`,
          average: `Average ${(categories.reduce((sum, a) => sum + a.averageCSAT.overall, 0) / categories.length).toFixed(2)}/5`,
          bgColor: 'bg-orange-100',
          link: '#',
        },
        {
          category: 'Strength',
          name: getDisplayName(strength.name),
          score: getDisplayName(strength.name),
          average: `Score: ${(strength.average * 20).toFixed(2)}%`,
          bgColor: 'bg-blue-100',
          link: '#',
        },
        {
          category: 'Improvement Area',
          name: getDisplayName(weakness.name),
          score: getDisplayName(weakness.name),
          average: `Score: ${(weakness.average * 20).toFixed(2)}%`,
          bgColor: 'bg-yellow-100',
          link: '#',
        },
      ]);
    }
  }, [categories]);

  return (
    <div className="p-4 bg-white rounded ">
      <h2 className="text-darkGrey font-semibold text-base mb-4">
        Top Performers
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {topPerformers.map((performer, index) => (
          <div
            key={index}
            className={`flex flex-col justify-start rounded p-5 gap-2 ${performer.bgColor}`}
          >
            <div className="text-darkGrey font-semibold text-base">
              {performer.category}
            </div>
            <div className="text-xl ">
              <span className="text-xl p-1 rounded font-semibold">
                {performer.score}
              </span>
              {/* <a href={performer.link} className="ml-2 text-blue-500 bg-white p-1 rounded text-sm">View Ticket ↗</a> */}
            </div>
            <div className="text-sm text-gray-600">{performer.average}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPerformers;
