import { usePostHog } from 'posthog-js/react';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { formatMinutes } from '../../common/util';
import DateRange from '../../components/date';
import { useAuthStore, useCategoryStore, useTicketStore } from '../../state';
import Sidebar from './components/Sidebar';

const CategoryDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { account, token, dateSelectionData, accountType } = useAuthStore();
  const { tickets, totalTickets, fetchTickets, ticketLoading } =
    useTicketStore();
  const { subCategories, fetchCategories } = useCategoryStore();
  const [showSortBy, setShowSortBy] = useState(false);
  const pageNo = useRef(1);
  const orderBy = useRef('desc');
  const posthog = usePostHog();
  useEffect(() => {
    const {
      range: { startDate, endDate },
    } = dateSelectionData;
    const fetchData = async () => {
      const query = `?account=${account}&start_date=${startDate}&end_date=${endDate}`;
      await fetchCategories(token, query);
    };
    fetchData();
  }, [dateSelectionData, account, token, fetchCategories]);

  useEffect(() => {
    (async () => {
      pageNo.current = 1;
      if (params.category && !params.subCategory) {
        const {
          range: { startDate, endDate },
        } = dateSelectionData;
        let query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=12&page=${pageNo.current}&sortBy=last_message_datetime&sortOrder=desc`;
        if (params.category === 'miscellaneous') {
          query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=12&page=${pageNo.current}&sortBy=last_message_datetime&sortOrder=desc`;
          subCategories &&
            subCategories['miscellaneous'].forEach((subCategory) => {
              query += `&subcategory=${subCategory.name}`;
            });
        }
        await fetchTickets(token, query);
      }
      if (params.category && params.subCategory) {
        const {
          range: { startDate, endDate },
        } = dateSelectionData;
        const query = `?account=${account}&category=${params.category}&subcategory=${params.subCategory}&startDate=${startDate}&endDate=${endDate}&size=12&page=${pageNo.current}&sortBy=last_message_datetime&sortOrder=desc`;
        await fetchTickets(token, query);
      }
    })();
  }, [params.category, params.subCategory, dateSelectionData]);

  const handlePageChange = async (page) => {
    if (page === 'prev') {
      pageNo.current = pageNo.current - 1;
    } else {
      pageNo.current = pageNo.current + 1;
    }
    console.log('pageNo', pageNo.current);
    const {
      range: { startDate, endDate },
    } = dateSelectionData;
    let query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=12&page=${pageNo.current}&sortBy=last_message_datetime&sortOrder=desc`;
    if (params.subCategory) {
      query += `&subcategory=${params.subCategory}`;
    }
    await fetchTickets(token, query);
  };
  const handleOrderBy = async (order) => {
    if (order === 'desc') {
      orderBy.current = 'desc';
    } else {
      orderBy.current = 'asc';
    }
    console.log('pageNo', orderBy.current);
    const {
      range: { startDate, endDate },
    } = dateSelectionData;
    let query = `?account=${account}&category=${params.category}&startDate=${startDate}&endDate=${endDate}&size=12&page=${pageNo.current}&sortBy=last_message_datetime&sortOrder=${orderBy.current}`;
    if (params.subCategory) {
      query += `&subcategory=${params.subCategory}`;
    }
    await fetchTickets(token, query);
  };
  return (
    <div className="flex bg-bgColor">
      <div className="flex-[0.10]">
        <Sidebar />
      </div>
      <div className="flex-[0.90] overflow-hidden">
        <div className="flex flex-col w-full font-onest min-w-full">
          <div className="flex flex-row w-full bg-white items-center justify-between px-5 py-2 gap-5 shrink-0 border-b border-b-disableColor border-t border-t-disableColor">
            <h3 className="relative text-black">
              <NavLink
                to="/pulse"
                className="no-underline text-black text-xl font-medium"
              >
                Pulse
              </NavLink>{' '}
              <span className="mx-2"> {`>`} </span>
              <NavLink
                to={`/pulse/${encodeURIComponent(params.category)}`}
                className="no-underline text-black text-xl font-medium capitalize"
              >
                {`${params.category}`}{' '}
              </NavLink>
              <span className="mx-2"> {`>`} </span>
              <span className="text-slateblue text-xl font-bold">Tickets</span>
            </h3>
            <div className="flex flex-row items-center justify-center gap-4">
              {params.category && params.subCategory ? (
                <>
                  <DateRange pageName={'View_category-view_ticket'} />
                </>
              ) : (
                <>
                  <DateRange pageName={'Page_view-view_ticket'} />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col px-5 text-textPrimary overflow-auto h-contentHeight pb-10">
          <div className="flex justify-between">
            <p className="font-semibold text-lg text-textPrimary py-4">
              {totalTickets} Tickets Received{' '}
            </p>
            <div className="flex items-center mr-4">
              <div>
                <p
                  onClick={() => {
                    setShowSortBy((prev) => !prev);
                  }}
                  className="bg-gray-300 text-sm text-black font-bold py-2 px-4 rounded cursor-pointer"
                >
                  {' '}
                  Sort By{' '}
                </p>
                {showSortBy && (
                  <div className="absolute right-4 text-xs w-max bg-white border border-gray-300 rounded mt-2 cursor-pointer">
                    <p
                      onClick={() => handleOrderBy('desc')}
                      className={`p-2 hover:bg-gray-300 cursor-pointer ${
                        orderBy.current === 'desc'
                          ? 'bg-highlightColor text-white'
                          : ''
                      }`}
                    >
                      {' '}
                      Last Message:Desc{' '}
                    </p>
                    <p
                      onClick={() => handleOrderBy('asc')}
                      className={`p-2 hover:bg-gray-300 cursor-pointer ${
                        orderBy.current === 'asc'
                          ? 'bg-highlightColor text-white'
                          : ''
                      }`}
                    >
                      {' '}
                      Last Message:Asc{' '}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!ticketLoading && (
            <div className=" bg-white p-4">
              <div className={`grid  grid-cols-5  w-full font-semibold pb-4`}>
                <p className="flex-1"> Ticket Id</p>
                <p className="flex-1 text-center"> First Response Time </p>
                <p className="flex-1  text-center"> Resolution Time</p>
                <p className="flex-1  text-center"> Reopen Count </p>
                <p className="flex-1  text-center"> AI CSAT </p>
              </div>
              {tickets &&
                tickets.map((ticket, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      if (params.category && !params.subCategory) {
                        navigate(
                          `/pulse/${encodeURIComponent(params.category)}/tickets/${ticket.ticketId}`
                        );
                        posthog.capture(
                          `Page_view _view_ticket_-view-${ticket.ticketId}`,
                          {
                            category_title: `${params.category}`,
                            ticketId: `${ticket.ticketId}`,
                          }
                        );
                      }
                      if (params.category && params.subCategory) {
                        navigate(
                          `/pulse/${encodeURIComponent(params.category)}/${encodeURIComponent(params.subCategory)}/tickets/${ticket.ticketId}`
                        );
                        posthog.capture(
                          `View_category_view_ticket-${ticket.ticketId}`,
                          {
                            category_title: `${params.category}`,
                            subcategory_title: `${params.subCategory}`,
                            ticketId: `${ticket.ticketId}`,
                          }
                        );
                      }
                    }}
                    className={`grid grid-cols-5 w-full border-b-disableColor border-b cursor-pointer p-4 font-normal text-xs hover:bg-bgColor`}
                  >
                    <p className="flex-1"> {ticket.ticketId} </p>
                    <p className="flex-1 text-center">
                      {' '}
                      {formatMinutes(
                        Math.floor(
                          ticket?.ticketMetric?.first_response_time_in_minutes
                            ?.business
                        )?.toFixed(2)
                      ) || '-'}
                    </p>
                    <p className="flex-1  text-center">
                      {' '}
                      {formatMinutes(
                        ticket?.ticketMetric?.full_resolution_time_in_minutes
                          ?.business
                      ) || '-'}
                    </p>
                    <p className="flex-1  text-center">
                      {' '}
                      {ticket?.ticketMetric?.reopens || '-'}
                    </p>
                    <p className="flex-1  text-center">
                      {' '}
                      {ticket.csatScore || '-'}
                    </p>
                  </div>
                ))}

              <div className="flex text-sm justify-end mt-4">
                <button
                  onClick={() => handlePageChange('prev')}
                  className="p-2 bg-gray-300"
                >
                  {' '}
                  Prev{' '}
                </button>
                <button
                  onClick={() => handlePageChange('next')}
                  className="ml-2 p-2 bg-gray-300"
                >
                  {' '}
                  Next{' '}
                </button>
              </div>
            </div>
          )}
          {ticketLoading && (
            <div className="flex justify-center bg-white items-center h-[70vh]">
              <p className="text-lg font-semibold"> Loading... </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetails;
