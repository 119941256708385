import React from 'react';

const ImageModal = ({ isOpen, closeModal, imageUrl }) => {
  const handleOutsideClick = (event) => {
    if (event.target.id === 'modalBackground') {
      closeModal();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      id="modalBackground"
      onClick={handleOutsideClick}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10"
    >
      <div className="relative rounded-lg p-4">
        <button
          onClick={closeModal}
          className="bg-red-400 absolute top-0 right-0 text-white  rounded-full w-10  "
        >
          <span className="text-white text-3xl">&times;</span>
        </button>
        <img src={imageUrl} alt="Full Screen" className="h-[90vh]" />
      </div>
    </div>
  );
};

export default ImageModal;
