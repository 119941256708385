import React, { useEffect, useRef, useState } from 'react';

// The HOC takes children as props and handles outside click behavior
const withOutsideClick = (WrappedComponent) => {
  const insideComponent = ({ isOpen: externalIsOpen, onToggle, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const componentRef = useRef(null);

    // Synchronize external and internal open state
    useEffect(() => {
      if (externalIsOpen !== undefined) {
        setIsOpen(externalIsOpen);
      }
    }, [externalIsOpen]);

    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
        if (onToggle) {
          onToggle(false);
        }
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [isOpen]);

    const toggleVisibility = () => {
      const newState = !isOpen;
      setIsOpen(newState);
      if (onToggle) {
        onToggle(newState);
      }
    };

    return (
      <div ref={componentRef}>
        <WrappedComponent
          {...props}
          isOpen={isOpen}
          toggleVisibility={toggleVisibility}
        />
      </div>
    );
  };
  return insideComponent;
};

export default withOutsideClick;
