import React, { useState } from 'react';

const Checkbox = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="mr-2 h-4 w-4 text-[#2564eb] focus:outline-none"
      />
      <label className=" text-sm">
        I accept the{' '}
        <span>
          <a
            href="https://kimcc.notion.site/Terms-and-conditions-2f3dd2f71c4845cd96c91ea3f59cb1d4?pvs=4"
            target="_blank"
            className=" text-[#2564eb] font-semibold"
          >
            {' '}
            terms and condition
          </a>
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
