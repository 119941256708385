import React, { useEffect, useRef } from 'react';
import withOutsideClick from 'components/withOutsideClick';
import { DashLg } from 'react-bootstrap-icons';
import DropDownSVG from 'assets/icons/dropdown.svg';

const MultiSelectDropdownBase = ({
    selectedOptions = [],
    setSelectedOptions = () => { },
    allOptions = [],
    handleOptionsChange = () => { },
    isOpen,
    toggleVisibility,
    placeholder = 'Select options',
    optionsText = 'options',
    optionText = 'option',
}) => {
    const controllerRef = useRef(null); // Use useRef to store the controller

    useEffect(() => {
        controllerRef.current = new AbortController();
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        // Abort the previous fetch request and create a new AbortController
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        // Determine the new set of selected options
        let newSelectedOptions = [];
        if (value === 'all') {
            newSelectedOptions = selectedOptions.length > 0 ? [] : allOptions;
        } else {
            newSelectedOptions = selectedOptions.includes(value)
                ? selectedOptions.filter((option) => option !== value)
                : [...selectedOptions, value];
        }
        setSelectedOptions(newSelectedOptions);

        // Trigger any side effects or callbacks
        handleOptionsChange(newSelectedOptions, controllerRef.current);
    };

    const renderSelectText = () => {
        const selectedCount = selectedOptions.length;
        const allCount = allOptions.length;
        if (selectedCount === allCount && selectedCount !== 0) {
            return `All ${optionsText}`;
        }
        if (selectedCount === 0) {
            return placeholder;
        }
        if (selectedCount === 1) {
            return `1 ${optionText}`;
        }
        return `${selectedCount} ${optionsText}`;
    };

    return (
        <div className="relative inline-block w-64 text-sm font-inter bg-bgGray">
            <div
                onClick={toggleVisibility}
                className="appearance-none w-full border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline cursor-pointer text-sm"
            >
                {renderSelectText()}
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <img src={DropDownSVG} alt="dropdown" />
                </div>
            </div>
            {isOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10 max-h-[50vh] overflow-y-auto">
                    <label className="inline-flex w-full px-4 py-2 text-gray-700 hover:bg-gray-100 relative cursor-pointer items-center ">
                        <input
                            type="checkbox"
                            value="all"
                            checked={selectedOptions.length === allOptions.length}
                            onChange={handleChange}
                            className={`${selectedOptions.length > 0 && selectedOptions.length !== allOptions.length
                                ? 'absolute opacity-0 h-0 w-0'
                                : 'mr-2 leading-tight'
                                }`}
                        />
                        {selectedOptions.length > 0 &&
                            selectedOptions.length !== allOptions.length && (
                                <>
                                    <DashLg className="bg-blue-500 text-white rounded-[3px] font-bold " />
                                </>
                            )}
                        <p
                            className={`${selectedOptions.length > 0 && selectedOptions.length !== allOptions.length
                                ? 'ml-2'
                                : ''
                                }`}
                        >
                            {selectedOptions.length > 0
                                ? selectedOptions.length == allOptions.length
                                    ? 'Deselect All'
                                    : 'Deselect'
                                : 'Select All'}
                        </p>
                    </label>
                    <div className="w-full border-t border-disableColor"></div>
                    {allOptions.map((option, index) => (
                        <label
                            key={index}
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                            <input
                                type="checkbox"
                                value={option}
                                checked={selectedOptions.includes(option)}
                                onChange={handleChange}
                                className="mr-2 leading-tight"
                            />
                            {option[0].toUpperCase() + option.slice(1)}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

const MultiSelectDropdown = withOutsideClick(MultiSelectDropdownBase);

export default MultiSelectDropdown;