import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuthStore } from 'state/auth';
import AgentPerformanceLeaderboard from './AgentPerformanceLeaderBoard';
import TopPerformers from './TopPerformers';

const AgentPerformanceContainer = ({ categories }) => {
    const { account, dateSelectionData } = useAuthStore();
    const { id } = useParams();

    if (!categories) {
        return <div>Loading...</div>;
    }

    return (
        <div className='flex flex-col gap-4 m-5'>
            <TopPerformers categories={categories} />
            <AgentPerformanceLeaderboard
                agentData={categories}
                agentId={id}
                account={account}
                start_date={dateSelectionData.range.startDate}
                end_date={dateSelectionData.range.endDate}
            />
        </div>
    );
};

export default AgentPerformanceContainer;