import { useEffect, useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import DateRange from '../components/date';
import { useAuthStore } from '../state';
const baseURL = process.env.REACT_APP_DEV_SERVER_URL;

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CopilotDashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalMessagedByRole, setTotalMessagedByRole] = useState({});
  const [averageResponseTime, setAverageResponseTime] = useState([]);
  const [averageResolutionTime, setAverageResolutionTime] = useState([]);
  const [lineData, setLineData] = useState([]);
  const { dateSelectionData } = useAuthStore();
  const fetchData = () => {
    const analyticsUrl = `${baseURL}/copilot/analytics/`;
    const {
      range: { startDate, endDate },
    } = dateSelectionData;
    const metricsUrl = `${baseURL}/copilot/metrics/?startDate=${startDate}&endDate=${endDate}`;

    fetch(analyticsUrl)
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setTotalMessagedByRole(json.totalMessagedByRole);
        setLoading(false);
      });

    fetch(metricsUrl)
      .then((response) => response.json())
      .then((json) => {
        setLineData(json);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="font-inter overflow-hidden">
      <div className="flex border-b border-b-disableColor items-center">
        <div className="flex-1 p-4 text-highlightColor font-onest text-xl font-bold">
          Dashboard
        </div>
        <div>
          <DateRange />
        </div>
      </div>
      {loading ? (
        <div className="p-4">Loading...</div>
      ) : (
        <div className="p-4 h-contentHeight overflow-auto">
          <div className="flex flex-col gap-8">
            <div className="grid grid-cols-3 gap-4 ">
              <div className="flex flex-col p-4 border rounded-md bg-white">
                <div className=" font-semibold text-lg">Conversations</div>
                <div className="p-4 bg-white rounded-md mt-4">
                  <div className="flex gap-8 items-center">
                    <div className="w-24 h-24">
                      <CircularProgressbarWithChildren
                        value={data['totalRouted']}
                        styles={buildStyles({
                          trailColor: '#93DADF',
                          pathColor: `#00A4A4`,
                          pathTransitionDuration: 0.5,
                        })}
                        minValue={0}
                        maxValue={data['totalConversations']}
                        strokeWidth={4}
                      >
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        <div className=" text-xl font-bold">
                          {data['totalConversations']}
                        </div>
                        <div className=" text-xs font-medium">Total</div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="flex-1 flex-row flex gap-8">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="rounded-full bg-[#00A4A4] h-4 w-4"></div>
                          <div className="ml-2 font-bold text-neutral-500 text-xs">
                            Routed
                          </div>
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-2">
                          {data['totalRouted']}
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-[0.125rem]">
                          {Math.floor(
                            (data['totalRouted'] / data['totalConversations']) *
                              100
                          )}
                          %
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="rounded-full bg-[#93DADF] h-4 w-4"></div>
                          <div className="ml-2 font-bold text-neutral-500 text-xs">
                            Unresolved
                          </div>
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-2">
                          {data['totalConversations'] - data['totalRouted']}
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-[0.125rem]">
                          {Math.floor(
                            ((data['totalConversations'] -
                              data['totalRouted']) /
                              data['totalConversations']) *
                              100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-4 border rounded-md bg-white">
                <div className=" font-semibold text-lg">Messages</div>
                <div className="p-4 bg-white rounded-md mt-4">
                  <div className="flex gap-8 items-center">
                    <div className="w-24 h-24">
                      <CircularProgressbarWithChildren
                        value={totalMessagedByRole['assistant']}
                        styles={buildStyles({
                          trailColor: '#93DADF',
                          pathColor: `#00A4A4`,
                          pathTransitionDuration: 0.5,
                        })}
                        minValue={0}
                        maxValue={totalMessagedByRole['user']}
                        strokeWidth={4}
                      >
                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                        <div className=" text-xl font-bold">
                          {totalMessagedByRole['user']}
                        </div>
                        <div className=" text-xs font-medium">Total</div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="flex-1 flex-row flex gap-8">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="rounded-full bg-[#00A4A4] h-4 w-4"></div>
                          <div className="ml-2 font-bold text-neutral-500 text-xs">
                            By Copilot
                          </div>
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-2">
                          {totalMessagedByRole['assistant']}
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-[0.125rem]">
                          {Math.floor(
                            (totalMessagedByRole['assistant'] /
                              totalMessagedByRole['user']) *
                              100
                          )}
                          %
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="rounded-full bg-[#93DADF] h-4 w-4"></div>
                          <div className="ml-2 font-bold text-neutral-500 text-xs">
                            By Agent
                          </div>
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-2">
                          {' '}
                          {totalMessagedByRole['agent'] || 0}
                        </div>
                        <div className=" text-neutral-700 font-bold text-xl mt-[0.125rem]">
                          {Math.floor(
                            ((totalMessagedByRole['agent'] || 0) /
                              totalMessagedByRole['user']) *
                              100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <div className=" font-semibold text-lg">Average FRT</div>
                <div
                  className="bg-white px-4 py-8 rounded-md mt-4"
                  style={{ height: '400px' }}
                >
                  <ResponsiveContainer>
                    <LineChart data={lineData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="_id" />
                      <YAxis unit="ms" />
                      <Tooltip formatter={(value) => value.toFixed(2)} />

                      <Line
                        type="monotone"
                        dataKey="averageFirstResponseTime"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="flex flex-col">
                <div className=" font-semibold text-lg">Average Wait time</div>
                <div
                  className="bg-white px-4 py-8 rounded-md mt-4"
                  style={{ height: '400px' }}
                >
                  <ResponsiveContainer>
                    <LineChart data={lineData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="_id" />
                      <YAxis unit="ms" />
                      <Tooltip formatter={(value) => value.toFixed(2)} />
                      <Line
                        type="monotone"
                        dataKey="averageResponseTime"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopilotDashboard;
