import {
  estimateCsatLable,
  formatMinutes,
  getSentiment,
  hasValue,
  labelColor,
} from '../../../../common/util';
import { useCategoryStore } from '../../../../state';
import CategoryConfigure from '../CategoryConfigure';
import Shimmer from './shimmer';

const Overview = ({
  categoryName = null,
  overviewDetails,
  categoriesNameList,
  excludedCategories,
  isLoading = false,
  topAgent = null,
}) => {
  const { selectedCategoriesName } = useCategoryStore();
  const isEmpty = () => {
    return selectedCategoriesName.length === 0;
  };

  return (
    <div className="flex flex-col items-start justify-start gap-2">
      <div className="flex felx-col justify-between w-full">
        <h3 className=" flex m-0 relative text-inherit leading-[20px] capitalize font-semibold font-inherit min-w-[83px]">
          overview
        </h3>
        <CategoryConfigure
          categoryName={categoryName}
          categoryNameList={categoriesNameList}
          excludedCategories={excludedCategories}
        />
      </div>
      {isLoading ? (
        <Shimmer />
      ) : (
        <div className="flex flex-row gap-6">
          <div className="rounded-sm bg-white flex flex-col items-start justify-center px-5 py-4 border border-disableColor min-w-[8rem] gap-1">
            <div className="text-neutral-500 text-base font-semibold capitalize">
              AI CSAT
            </div>
            <div className="flex flex-row items-center">
              <p className="text-xl font-bold capitalize  text-black">
                {isEmpty()
                  ? '-'
                  : overviewDetails?.averageCSAT?.toFixed(2) + '/5'}
              </p>
              {!isEmpty() && (
                <div
                  className={`rounded-sm flex text-xs font-medium capitalize items-center justify-center p-1 text-3xs text-black ${labelColor(
                    overviewDetails?.averageCSAT
                  )}`}
                >
                  {estimateCsatLable(overviewDetails?.averageCSAT?.toFixed(2))}
                </div>
              )}
            </div>
          </div>
          <div className="rounded-sm bg-white px-5 py-4 border border-disableColor flex flex-col justify-center min-w-[8rem] gap-1">
            <div className="text-neutral-500 text-base font-semibold capitalize">
              Avg Sentiment
            </div>
            <p className="text-xl font-bold capitalize  text-black">
              {isEmpty() ? '-' : getSentiment(overviewDetails?.averageCSAT)}
            </p>
          </div>
          {hasValue(overviewDetails?.averageResponseTime) && (
            <>
              <div className="rounded-sm bg-white px-5 py-4 border border-disableColor flex flex-col justify-center min-w-[8rem] gap-1">
                <div className="text-neutral-500 text-base font-semibold capitalize">
                  Reopen Rate
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px] text-xl text-darkslateblue">
                  <p className="font-bold text-black">
                    {isEmpty() ? (
                      '-'
                    ) : hasValue(overviewDetails?.reopenRate) ? (
                      <>{overviewDetails?.reopenRate?.toFixed(2)}%</>
                    ) : (
                      0.0
                    )}
                  </p>
                </div>
              </div>

              <div className="rounded-sm bg-white px-5 py-4 border border-disableColor flex flex-col justify-center min-w-[8rem] gap-1">
                <div className="text-neutral-500 text-base font-semibold capitalize">
                  Resolution Time
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px] text-xl text-darkslateblue">
                  <p className="font-bold text-black">
                    {isEmpty() ? (
                      '-'
                    ) : hasValue(overviewDetails?.averageResponseTime) ? (
                      <>
                        {formatMinutes(
                          Math.floor(overviewDetails?.averageResponseTime)
                        )}
                      </>
                    ) : (
                      <>{formatMinutes(0)}</>
                    )}
                  </p>
                </div>
              </div>

              <div className="rounded-sm bg-white px-5 py-4 border border-disableColor flex flex-col justify-center min-w-[8rem] gap-1">
                <div className="text-neutral-500 text-base font-semibold capitalize">
                  First Response Time
                </div>
                <div className="flex flex-row items-center justify-start gap-[4px] text-xl text-darkslateblue">
                  <p className="font-bold text-black">
                    {isEmpty()
                      ? '-'
                      : hasValue(
                          overviewDetails?.averageFirstResolutionTime
                        ) && (
                          <>
                            {formatMinutes(
                              Math.floor(
                                overviewDetails?.averageFirstResolutionTime
                              )?.toFixed(2)
                            )}
                          </>
                        )}
                  </p>
                </div>
              </div>
            </>
          )}

          {topAgent && (
            <div className="rounded-sm bg-white flex flex-col items-start justify-center px-5 py-4 border border-disableColor min-w-[8rem] gap-1">
              <div className="text-neutral-500 text-base font-semibold capitalize">
                Highest Rated Agent
              </div>
              <div className="flex flex-row items-center">
                <p className="text-xl font-bold capitalize  text-black">
                  {isEmpty() ? '-' : topAgent?.agent_name}
                </p>
                {!isEmpty() && (
                  <div
                    className={`rounded-sm flex text-xs font-medium capitalize items-center justify-center p-1 ml-1 text-3xs text-black ${labelColor(
                      topAgent?.kimScore
                    )}`}
                  >
                    {/* {estimateCsatLable(overviewDetails?.averageCSAT?.toFixed(2))}*/}
                    {isEmpty() ? '-' : topAgent?.kimScore + '/5'}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
