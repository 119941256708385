import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

const useComponentTiming = (pageName) => {
  const posthog = usePostHog();

  const [startTime] = useState(Date.now);

  useEffect(() => {
    const [navigationTiming] = performance.getEntriesByType('navigation');
    let loadTime;
    if (navigationTiming) {
      loadTime = navigationTiming.loadEventEnd - navigationTiming.startTime;
    }

    posthog.capture(`${pageName}_loading`, {
      timestamp: new Date().toISOString(),
      loadingTime: loadTime,
    });
  }, [pageName]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const unmountTime = Date.now();
      const duration = (unmountTime - startTime) / 1000;

      posthog.capture(`${pageName}_duration`, {
        timestamp: new Date().toISOString(),
        duration,
      });
    };

    return () => {
      handleBeforeUnload();
    };
  }, [pageName]);
};

export default useComponentTiming;
