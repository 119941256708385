import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadCSV, getRandomColor } from '../../common/util';
import DateRange from '../../components/date';
import useComponentTiming from '../../components/useComponentTiming';
import { useAuthStore, useCategoryStore } from '../../state';
import MultiSelectDropdown from './components/MultiSelectDropdown';
// import TrendChart from './components/TrendChart';
import { getAgentsPerformance } from 'api/agentPerformanceApi';
import { getOverview } from '../../api/categoriesApi';
import { colors } from '../../common/constant';
import DonutChart from '../../components/DonutChart';
import AgentPerformance from './components/AgentPerformance';
import Overview from './components/Overview';
import { Shimmer } from './components/shimmer.js';

const Category = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const topAgent =
    agents.length > 0
      ? agents.reduce((prev, current) =>
          prev.kimScore > current.kimScore ? prev : current
        )
      : null;
  const { token, account, dateSelectionData } = useAuthStore();
  console.log(account);
  const {
    fetchTopLevelCategoriesDetail,
    allCategories,
    selectedCategoriesName,
    categoriesLoading,
    categoriesNameList,
    excludedCategories,
    updateState,
    overviewDetails,
    overviewLoading,
  } = useCategoryStore();
  const selectedCategories = useMemo(() => {
    return allCategories.filter((cat) =>
      selectedCategoriesName.includes(cat.name)
    );
  }, [allCategories, selectedCategoriesName]);

  const totalTicketsCount = useMemo(() => {
    return selectedCategories.reduce((acc, cat) => acc + cat.count, 0);
  }, [selectedCategories]);

  const chartData = useMemo(() => {
    return selectedCategories.map((cat, idx) => ({
      name: cat.name,
      value: Number(
        parseFloat((cat.count * 100) / totalTicketsCount)?.toFixed(2) ?? 0
      ),
      color: colors[idx] ?? getRandomColor(),
    }));
  }, [selectedCategories]);

  const allCategoriesNameList = useMemo(() => {
    return allCategories.map((cat) => cat.name);
  }, [allCategories]);

  const posthog = usePostHog();
  const chart = useRef(null);
  useComponentTiming('PageView');
  useEffect(() => {
    posthog.capture('PageView-Pulse');
  }, []);

  const handleOverviewChange = async (options, controller = null) => {
    try {
      const {
        range: { startDate, endDate },
      } = dateSelectionData;
      updateState('overviewLoading', true);
      const response = await getOverview(
        token,
        account,
        options,
        startDate,
        endDate,
        { signal: controller?.signal }
      );
      updateState('overviewDetails', response[0]);
      updateState('overviewLoading', false);
    } catch (error) {
      if (error.name === 'AbortError') {
        // console.log('Fetch aborted due to new request or unmount');
        // Do not set loading false here because the fetch was intentionally aborted
      } else {
        console.log('Error in fetching overview:', error);
        updateState('overviewLoading', false);
      }
    }
  };

  const updateSelectedCategories = (val) => {
    updateState('selectedCategoriesName', val);
  };

  //api call for fetch category
  useEffect(() => {
    const fetchData = async () => {
      await fetchTopLevelCategoriesDetail();
    };
    fetchData();
  }, [dateSelectionData, account, token, fetchTopLevelCategoriesDetail]);
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const {
          range: { startDate, endDate },
        } = dateSelectionData;
        const data = await getAgentsPerformance(
          account,
          startDate,
          endDate,
          selectedCategoriesName
        );
        setAgents(data);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };
    if (selectedCategoriesName && selectedCategoriesName?.length > 0) {
      fetchAgents();
    } else {
      setAgents([]);
    }
  }, [account, dateSelectionData, selectedCategoriesName]);

  return (
    <>
      <div className="bg-bgColor overflow-hidden flex flex-row items-start justify-start ">
        <div className="flex flex-col w-full items-start justify-start text-xl text-black font-onest min-w-full h-screenHeight">
          {/* topbar start */}
          <div className="flex flex-row w-full bg-white items-center justify-between px-5 py-4 gap-5 shrink-0 border-b border-b-disableColor border-t border-t-disableColor">
            <p className="text-xl text-highlightColor font-bold">Pulse</p>
            <div className="flex flex-row items-center justify-center gap-4">
              <MultiSelectDropdown
                allOptions={categoriesNameList}
                selectedOptions={selectedCategoriesName}
                setSelectedOptions={updateSelectedCategories}
                handleOptionsChange={handleOverviewChange}
              />
              <DateRange pageName="Page_View" />
              <button
                onClick={() => {
                  DownloadCSV(selectedCategories, 'Category');
                  posthog.capture('Page_view-download', {
                    page_title: 'Pulse',
                  });
                }}
                className="cursor-pointer [border:none] px-4 py-2 bg-slateblue rounded-full flex flex-row items-center justify-center hover:bg-royalblue-200"
              >
                <img
                  className="h-4 w-4 relative overflow-hidden shrink-0"
                  alt=""
                  src="/fi-3502477.svg"
                />
                <b className="relative text-sm capitalize font-onest text-white text-left">
                  Download
                </b>
              </button>
            </div>
          </div>

          {categoriesLoading ? (
            <Shimmer />
          ) : (
            <div className="flex flex-row items-start justify-start font-inter px-5 pt-5 pb-10 overflow-y-auto h-contentHeight w-full">
              <div className="flex flex-col max-w-full w-full gap-4">
                <Overview
                  overviewDetails={overviewDetails}
                  categoriesNameList={allCategoriesNameList}
                  excludedCategories={excludedCategories}
                  isLoading={overviewLoading}
                  topAgent={topAgent}
                />
                <div className="flex flex-col items-start justify-start gap-4 max-w-full w-full">
                  <div className="flex flex-col gap-4 w-full">
                    <div className=" text-darkGrey font-semibold text-lg">
                      Category overview
                    </div>
                    <div className="grid grid-cols-5">
                      <div
                        ref={chart}
                        className="col-span-2 rounded-sm bg-white flex items-center"
                      >
                        <DonutChart
                          outerRadius={150}
                          innerRadius={90}
                          total={totalTicketsCount}
                          data={chartData}
                        />
                      </div>
                      <div className="col-span-3 flex flex-col rounded-sm bg-white p-5 gap-4 h-[50vh] overflow-y-auto">
                        <div
                          className={`grid gap-2 grid-cols-6 justify-center font-onest text-sm font-semibold text-black h-max`}
                        >
                          <div className="flex justify-start py-0 pr-[30px] pl-0 col-span-2">
                            <div className=" capitalize font-semibold col-span-1">
                              Category
                            </div>
                          </div>
                          <div className="capitalize font-semibold text-end col-span-1">{`# Tickets `}</div>
                          <div className="capitalize text-end font-semibold col-span-1">
                            AI CSAT/5
                          </div>

                          <div className="capitalize text-center items-center justify-                                                                                         font-semibold col-span-2">
                            Actions
                          </div>
                        </div>
                        <div className=" flex flex-col  divide-y divide-disableColor">
                          {selectedCategories.length === 0 && (
                            <div className="flex flex-row items-center justify-center h-40">
                              <div className="text-darkGrey font-semibold text-lg">
                                No data available for the selected dates or
                                category
                              </div>
                            </div>
                          )}
                          {selectedCategories.map((category, idx) => (
                            <div
                              key={idx}
                              className={`grid gap-2 py-4 text-darkGrey grid-cols-6`}
                            >
                              <div className="flex flex-row items-center gap-2 col-span-2">
                                <div
                                  className="w-3 h-3 relative rounded-[50%]"
                                  style={{
                                    backgroundColor: chartData.find(
                                      (cat) => cat.name === category.name
                                    ).color,
                                  }}
                                />
                                <div
                                  className="relative capitalize font-medium text-xs truncate"
                                  title={`${category.name}`}
                                >
                                  {category.name}
                                </div>
                              </div>
                              <div className="flex flex-row text-center items-center justify-end relative lowercase font-medium  text-xs col-span-1">
                                {category.count}
                              </div>
                              <div className="flex flex-row text-center items-center justify-end relative capitalize font-medium text-xs col-span-1">
                                {/* {(
                                  (parseFloat(
                                    (
                                      category.total_csat?.toFixed(2) /
                                      category.count
                                    )?.toFixed(2)
                                  ) /
                                    5) *
                                  100
                                )?.toFixed(2)}
                                % */}
                                {parseFloat(
                                  category.total_csat?.toFixed(2) /
                                    category.count
                                )?.toFixed(2)}
                              </div>

                              <div className="flex flex-col items-center justify-center text-3xs text-slateblue col-span-2">
                                <div className="flex flex-row gap-5 text-3xs">
                                  <div className="flex flex-col items-start justify-start">
                                    <div
                                      className="flex flex-row items-start justify-start gap-[2px] cursor-pointer"
                                      onClick={() => {
                                        navigate(
                                          `/pulse/${encodeURIComponent(category.name)}`
                                        );
                                        posthog.capture(
                                          `View_Category-${category.name}`,
                                          {
                                            categoryName: `${category.name}`,
                                            pageTitle: 'Pulse',
                                          }
                                        );
                                      }}
                                    >
                                      <div className="relative leading-[110%] capitalize font-semibold min-w-[72px] text-xs">
                                        View Category
                                      </div>
                                      <div className="flex flex-col items-start justify-start ml-1">
                                        <img
                                          className="w-2.5 h-2.5 relative overflow-hidden shrink-0"
                                          loading="lazy"
                                          alt=""
                                          src="/fi-152356.svg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="flex flex-row items-start justify-start gap-[2px] cursor-pointer"
                                    onClick={() => {
                                      navigate(
                                        `/pulse/${encodeURIComponent(category.name)}/tickets`
                                      );
                                      posthog.capture('Page_view-view_ticket', {
                                        categoryName: `${category.name}`,
                                        page_title: 'Pulse',
                                      });
                                    }}
                                  >
                                    <div className="relative leading-[110%] capitalize font-semibold min-w-[36px] text-xs">
                                      Tickets
                                    </div>
                                    <div className="flex flex-col items-start justify-start ml-1">
                                      <img
                                        className="w-2.5 h-2.5 relative overflow-hidden shrink-0"
                                        alt=""
                                        src="/fi-152356.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {account != 'paintingtogogh' && (
                  <AgentPerformance agents={agents} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Category;
