import { useEffect, useState } from 'react';

const TopPerformers = ({ agents }) => {
  const [topPerformers, setTopPerformers] = useState([]);

  const formatTimeToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    if (hours === 0) {
      return `${remainingMinutes}m`;
    }
    return `${hours}h ${remainingMinutes}m`;
  };

  useEffect(() => {
    if (agents.length > 0) {
      const topKimScore = agents.reduce((prev, current) =>
        prev.kimScore > current.kimScore ? prev : current
      );
      const topResponseTime = agents.reduce((prev, current) =>
        prev.averageResponseTime < current.averageResponseTime ? prev : current
      );
      const topFirstResolutionTime = agents.reduce((prev, current) =>
        prev.averageFirstResolutionTime < current.averageFirstResolutionTime
          ? prev
          : current
      );
      const topTicketsClosed = agents.reduce((prev, current) =>
        prev.totalTickets > current.totalTickets ? prev : current
      );

      // Calculate averages for all metrics
      const avgKimScore =
        agents.reduce((sum, agent) => sum + parseFloat(agent.kimScore), 0) /
        agents.length;
      const avgResponseTime =
        agents.reduce((sum, agent) => sum + agent.averageResponseTime, 0) /
        agents.length;
      const avgFirstResolutionTime =
        agents.reduce(
          (sum, agent) => sum + agent.averageFirstResolutionTime,
          0
        ) / agents.length;
      const avgTicketsClosed =
        agents.reduce((sum, agent) => sum + agent.totalTickets, 0) /
        agents.length;

      setTopPerformers([
        {
          category: 'By Kim Score',
          name: topKimScore.agent_name,
          score: `${topKimScore.kimScore}`,
          average: `Average ${avgKimScore.toFixed(2)}`,
          bgColor: 'bg-pink-100',
        },
        {
          category: 'By First Response Time',
          name: topFirstResolutionTime.agent_name,
          score: formatTimeToHoursAndMinutes(
            topFirstResolutionTime.averageFirstResolutionTime
          ),
          average: `Average ${formatTimeToHoursAndMinutes(avgFirstResolutionTime)}`,
          bgColor: 'bg-orange-100',
        },
        {
          category: 'By Resolution Time',
          name: topResponseTime.agent_name,
          score: formatTimeToHoursAndMinutes(
            topResponseTime.averageResponseTime
          ),
          average: `Average ${formatTimeToHoursAndMinutes(avgResponseTime)}`,
          bgColor: 'bg-blue-100',
        },
        {
          category: 'By #Tickets Closed',
          name: topTicketsClosed.agent_name,
          score: `${topTicketsClosed.totalTickets}`,
          average: `Average ${avgTicketsClosed.toFixed(2)}`,
          bgColor: 'bg-yellow-100',
        },
      ]);
    }
  }, [agents]);

  return (
    <div className="p-4 font-inter rounded">
      <h2 className="text-darkGrey font-semibold text-lg mb-4">
        Top Performers
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {topPerformers.map((performer, index) => (
          <div
            key={index}
            className={`flex flex-col justify-start rounded p-5 gap-2 ${performer.bgColor}`}
          >
            <div className="text-darkGrey font-semibold text-base">
              {performer.category}
            </div>
            <div className="text-xl font-semibold">
              {performer.name}
              <div className=" pl-2 inline-block text-xl font-semibold">
                <span className="text-sm bg-white p-1 rounded">
                  {performer.score}
                </span>
              </div>
            </div>

            <div className="text-sm text-gray-600">{performer.average}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPerformers;
