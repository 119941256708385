import React from 'react';

const Shimmer = () => {
  return (
    <div className="flex flex-row gap-6 animate-pulse">
      <div className="rounded-sm bg-gray-300 flex flex-col items-start justify-center px-5 py-4 border border-gray-300 min-w-[8rem] gap-1 h-[90px]">
        {/* Empty container mimics loading state with set height */}
      </div>
      <div className="rounded-sm bg-gray-300 px-5 py-4 border border-gray-300 flex flex-col justify-center min-w-[8rem] gap-1 h-[90px]">
        {/* Empty container mimics loading state with set height */}
      </div>
      <div className="rounded-sm bg-gray-300 px-5 py-4 border border-gray-300 flex flex-col justify-center min-w-[8rem] gap-1 h-[90px]">
        {/* Empty container mimics loading state with set height */}
      </div>
      <div className="rounded-sm bg-gray-300 px-5 py-4 border border-gray-300 flex flex-col justify-center min-w-[8rem] gap-1 h-[90px]">
        {/* Empty container mimics loading state with set height */}
      </div>
      <div className="rounded-sm bg-gray-300 px-5 py-4 border border-gray-300 flex flex-col justify-center min-w-[8rem] gap-1 h-[90px]">
        {/* Empty container mimics loading state with set height */}
      </div>
    </div>
  );
};

export default Shimmer;
