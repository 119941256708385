import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AndiesLogo from '../assets/andies_logo.png';
import gradient from '../assets/gradient.svg';
import greenGradient from '../assets/green-gradient.png';
import KimLogo from '../assets/kim-logo-transparent.png';
import MugsyLogo from '../assets/mugsy_logo.avif';
import OneskinLogo from '../assets/one_skin_logo.webp';
import PTGLogo from '../assets/ptg_logo.avif';
import { useAuthStore } from '../state/auth';

const baseURL = process.env.REACT_APP_DEV_SERVER_URL;

const backgroundStyle = {
  backgroundImage: `url(${gradient})`,
  backgroundSize: 'cover',
};
const greenBackgroundStyle = {
  backgroundImage: `url(${greenGradient})`,
  backgroundSize: 'cover',
};

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthStore();
  const queryParams = new URLSearchParams(location.search);
  const posthog = usePostHog();

  const from = queryParams.get('from');
  useEffect(() => {
    if (props.isLoggedIn) {
      navigate(from);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
    };

    console.log(baseURL, '---baseURL');
    const resp = await fetch(`${baseURL}/user/login`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await resp.json();
    if (resp.status === 200) {
      localStorage.setItem('token', result.token);
      localStorage.setItem('email', email);
      localStorage.setItem('account', result.account);
      auth.updateAuth({
        token: result.token,
        name: result.user.name,
        account: result.user.account,
        accountType: result.user.account_type,
      });
      posthog.register({
        brandName: result.user.account,
        userName: result.user.name,
        email_id: email,
        timestamp: new Date().toISOString(),
      });
      posthog.capture('Brand_login');
      posthog.identify(email);
      if (result.user.account === 'valeo') {
        window.location.href = '/copilot';
      } else {
        window.location.href = '/pulse';
      }
    } else {
      if (result?.msg) {
        setError(result?.msg);
      } else {
        setError('Something went wrong');
      }
    }
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex flex-1 justify-center items-center min-h-screen relative">
        <div className="flex justify-center flex-col mx-[4rem] max-w-lg">
          <div>
            <img src={KimLogo} className="max-w-[10rem]" />
          </div>
          <div className=" text-[#444] font-semibold text-4xl mt-[2.5rem] mb-[1.5rem]">
            Get started in a minute
          </div>
          <div className=" text-xl text-[#444] font-medium">
            <div>1. Faster hiring with AI powered matching system</div>
            <div>
              2. Deeper insights with AI powered customer conversation analysis
            </div>
            <div>
              3. More control on brand voice with AI powered quality monitoring
            </div>
            <div>4. Faster resolution with AI copilot for VAs</div>
          </div>
        </div>
        <div className=" pb-4 bottom-0 absolute mx-[2rem]">
          <p className=" text-base text-[#444] mb-[1rem] font-semibold">
            Our trusted brands
          </p>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <img src={MugsyLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={PTGLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={AndiesLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={OneskinLogo} className="max-w-[8rem]" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-1 min-h-screen justify-center items-center flex"
        style={backgroundStyle}
      >
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-3xl font-semibold text-[#444]">
              Welcome back!
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="focus:outline-none appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md"
                  placeholder="your company email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError('');
                  }}
                />
              </div>
              <div className="pt-[1.25rem]">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="focus:outline-none appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError('');
                  }}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-[#444] bg-[#fabf01] font-bold"
              >
                SIGN IN
              </button>
            </div>
          </form>
          {error ? <p className=" text-red-400">{error}</p> : ''}
          <div className=" text-[#444] text-xl font-medium">
            Don't have an account?{' '}
            <a
              href={`https://kim.cc/demo/`}
              className=" underline"
              target="_blank"
              rel="noreferrer"
            >
              Book a demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Signup = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get('from');
  const posthog = usePostHog();
  useEffect(() => {
    if (props.isLoggedIn) {
      navigate(from);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      password,
      name,
      account: accountName,
    };

    const resp = await fetch(`${baseURL}/user/signup`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const result = await resp.json();
    if (resp.status === 200) {
      localStorage.setItem('token', result.token);
      localStorage.setItem('email', email);
      localStorage.setItem('name', name);
      localStorage.setItem('account', result.user.account);
      navigate('/connect/zendesk');
      posthog.register({
        brandName: accountName,
        userName: name,
        email_id: email,
        timestamp: new Date().toISOString(),
      });
      posthog.capture('Brand_signed_up');
      posthog.identify(email);
    } else {
      if (result?.msg) {
        setError(result?.msg);
      } else {
        setError('Something went wrong');
      }
    }
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex flex-1 justify-center items-center min-h-screen relative">
        <div className="flex justify-center flex-col mx-[4rem] max-w-lg">
          <div>
            <img src={KimLogo} className="max-w-[10rem]" />
          </div>
          <div className=" text-[#444] font-semibold text-4xl mt-[2.5rem] mb-[1.5rem]">
            Get started in a minute
          </div>
          <div className=" text-xl text-[#444] font-medium">
            <div>1. Faster hiring with AI powered matching system</div>
            <div>
              2. Deeper insights with AI powered customer conversation analysis
            </div>
            <div>
              3. More control on brand voice with AI powered quality monitoring
            </div>
            <div>4. Faster resolution with AI copilot for VAs</div>
          </div>
        </div>
        <div className=" pb-4 bottom-0 absolute mx-[2rem]">
          <p className=" text-base text-[#444] mb-[1rem] font-semibold">
            Our trusted brands
          </p>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <img src={MugsyLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={PTGLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={AndiesLogo} className="max-w-[8rem]" />
            </div>
            <div>
              <img src={OneskinLogo} className="max-w-[8rem]" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-1 min-h-screen justify-center items-center flex"
        style={backgroundStyle}
      >
        <div className="max-w-md w-full space-y-8 flex justify-center flex-col">
          <div>
            <h2 className="mt-6 text-3xl font-semibold text-[#444]">
              Create a new account
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="focus:outline-none appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md"
                  placeholder="company email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError('');
                  }}
                />
              </div>
              <div className=" py-[1.25rem]">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md focus:outline-none"
                  placeholder="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError('');
                  }}
                />
              </div>
              <div className=" pb-[1.25rem]">
                <label htmlFor="name" className="sr-only">
                  Account Name
                </label>
                <input
                  id="account name"
                  name="account name"
                  type="text"
                  autoComplete="account name"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md focus:outline-none"
                  placeholder="account name"
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                    setError('');
                  }}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-[#444] placeholder-gray-500 text-gray-900 rounded-md focus:outline-none"
                  placeholder="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError('');
                  }}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-[#444] bg-[#fabf01] font-bold"
              >
                SIGN UP
              </button>
            </div>
            {error ? <p className=" text-red-400">{error}</p> : ''}
            <div className=" text-[#444] text-xl font-medium">
              Already have an account?{' '}
              <a
                href={`/login${window.location.search}`}
                className=" underline"
              >
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { Login, Signup };
