import React from 'react';
const CalendarShimmer = () => {
  return (
    <div className="grid grid-cols-7 gap-1">
      {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
        <div
          key={day}
          className="bg-gray-200 animate-pulse h-10 border border-gray-300"
        ></div>
      ))}
      {Array.from({ length: 35 }, (_, index) => (
        <div
          key={index}
          className="bg-gray-200 animate-pulse h-24 border border-gray-300"
        ></div>
      ))}
    </div>
  );
};

export default CalendarShimmer;
