// import config from '../config';

// const axios = require('axios');
import axios from 'axios';

const baseURL = process.env.REACT_APP_DEV_SERVER_URL;

export const getCategories = async (accessToken, query) => {
  let url = baseURL + `/common/category`;
  if (query) {
    url += query;
  }
  const options = {
    headers: {
      'content-type': 'application/json',
      'x-access-token': accessToken,
    },
    timeout: 30000,
    responseType: 'json',
    responseEncoding: 'utf8',
  };
  const response = await axios.get(url, options);
  return response.data;
};
