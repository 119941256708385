import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const TopBarContent = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate('/pulse/time-diary');
  };
  return (
    <div className="flex items-center justify-between rounded-lg w-full">
      <div
        className="text-blue-600 text-lg font-bold cursor-pointer"
        onClick={handleOnClick}
      >
        Time Diary
      </div>
    </div>
  );
};

export default TopBarContent;
