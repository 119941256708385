import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTimeTrackerStore } from '../../../../../../state';
import ImageDateDisplay from '../ImageDateDisplay';
import ImageModal from '../ImageModel';
import { MAX_IMAGES_PER_PAGE } from '../../../../../../state/useTimeTracker';
import ImageShimmer from '../ImageShimmer';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const SessionView = () => {
  const {
    agents,
    selectedAgent,
    isDailyImagesLoading,
    isDisplayImagesLoading,
    displayImages,
    dailyImages,
    countOfImages,
    imagePage,
    fetchDisplayImagesForPage,
    updateState,
  } = useTimeTrackerStore();
  const agentName = agents.find((agent) => agent._id === selectedAgent)?.name;
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageData, setImageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchDisplayImagesForPage(imagePage);
  }, [imagePage, dailyImages]);
  useEffect(() => {
    setTotalPages(Math.ceil(countOfImages / MAX_IMAGES_PER_PAGE));
  }, [countOfImages]);

  useEffect(() => {
    setImageData(displayImages);
  }, [displayImages]);

  const prevPage = () => {
    if (imagePage > 1) {
      updateState('imagePage', imagePage - 1);
    }
  };

  const nextPage = () => {
    if (imagePage < totalPages) {
      updateState('imagePage', imagePage + 1);
    }
  };

  const renderContent = () => {
    if (isDailyImagesLoading || isDisplayImagesLoading) {
      //display 20 shimmer images
      return Array.from({ length: 20 }).map((_, idx) => (
        <ImageShimmer key={idx} />
      ));
    } else if (imageData.length === 0) {
      return <div className="text-center text-lg">No Images Found</div>; // Message when no data is available
    } else {
      return imageData.map((item, idx) => (
        <ImageDateDisplay
          key={idx}
          date={item.date ?? new Date()}
          imageUrl={item.imageUrl}
          onClick={() => {
            setIsOpen(true);
            setImageUrl(item.imageUrl);
          }}
        />
      ));
    }
  };

  return (
    <div className="bg-white w-full h-full">
      <ImageModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        imageUrl={imageUrl}
      />
      <div className="w-full p-5">
        <div className="flex flex-row items-center justify-between">
          <Link to="/pulse/time-diary" className="flex text-blue-600">
            {' '}
            {'<- '}Back To Calendar
          </Link>
          <div className="flex ">
            {' '}
            <div className="text-md p-1">{`Page ${imagePage} of ${totalPages}`}</div>
            <button onClick={prevPage} className="p-1 text-lg rounded ">
              <ChevronLeft size={20} />
            </button>
            <button onClick={nextPage} className="p-1 text-lg rounded ">
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
        <div className="text-xl font-bold mt-5">{`${agentName?.split(' ')[0]}'s TimeProofs`}</div>
      </div>
      <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-5 p-5  border">
        {renderContent()}
      </div>
    </div>
  );
};

export default SessionView;
