const baseURL = process.env.REACT_APP_DEV_SERVER_URL;

export async function checkUserAuthentication() {
  try {
    const response = await fetch(`${baseURL}/common/check`, {
      method: 'GET',
      headers: {
        'x-access-token': localStorage.getItem('token'),
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Not Authenticated');
    }
  } catch (error) {
    throw error;
  }
}
