import React, { useState } from 'react';
import KimLogo from '../../../assets/kim-white.svg';
import { ChevronRight, Person } from 'react-bootstrap-icons';

const SideBar = () => {
  const [tab, setTab] = useState('discover');

  return (
    <>
      {' '}
      <div className="bg-[#181B4C] shadow-md px-4 w-[160px]">
        <div className=" flex  flex-col py-4 font-inter">
          <div className="text-xl">
            <img src={KimLogo} className="max-w-[4rem]" />
          </div>
          <div className=" text-white flex flex-col relative mt-[3rem]">
            <div
              className={`text-md font-semibold p-2 cursor-not-allowed border-b-1 border-transparent  ${
                tab === 'voc' ? 'border-white text-white' : 'text-[#d8d8d8]'
              }`}
            >
              Pulse
            </div>
            <div
              className={`text-md font-semibold p-2 cursor-pointer ${
                tab === 'copilot'
                  ? 'border-white text-white border-b text-lg font-bold'
                  : 'text-[#d8d8d8]'
              }`}
              onClick={() => setTab('discover')}
            >
              Discover
            </div>
          </div>
          <div className="text-white text-md absolute bottom-4">
            <div className="flex items-center">
              <Person />
              <p className=" font-medium ml-2">
                {localStorage.getItem('name')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
