import { addMinutes, endOfDay, format, startOfDay } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useAuthStore } from '../state';
import withOutsideClick from './withOutsideClick';

const DateRangeComponent = ({ pageName, isOpen, toggleVisibility }) => {
  const { updateDateSelection, dateSelectionData } = useAuthStore();
  const posthog = usePostHog();
  // const [showMenu, setShowMenu] = useState(false);
  const [calendarViewState, setCalendarViewState] = useState('initial'); // 'initial' or 'updated'
  const [dataObj, setDateObj] = useState({
    key: 'selection',
    startDate:
      dateSelectionData?.range?.startDate ||
      new Date(new Date().setDate(new Date().getDate() - 8)),
    endDate:
      dateSelectionData?.range?.endDate ||
      new Date(new Date().setDate(new Date().getDate() - 2)),
  });

  const dateUpdationCall = (data) => {
    setDateObj({
      key: 'selection',
      startDate: data.range.startDate,
      endDate: data.range.endDate,
    });
    updateDateSelection(data);
    setCalendarViewState('intial');
    // setShowMenu(false);
    toggleVisibility();
    posthog.capture(`${pageName}-view_date_range-selection`);
  };

  const isDateSame = (date1, date2) => {
    return date1.toDateString() === date2.toDateString();
  };

  const handleSelection = ({ selection }) => {
    const formattedStartDate = startOfDay(selection.startDate);
    const adjustedEndDate = endOfDay(selection.endDate);

    if (
      calendarViewState === 'initial' &&
      isDateSame(selection.startDate, selection.endDate)
    ) {
      setCalendarViewState('updated');
      setDateObj({
        key: 'selection',
        startDate: formattedStartDate,
        endDate: adjustedEndDate,
      });
    } else {
      dateUpdationCall({
        selection: 'selection',
        range: {
          startDate: formattedStartDate,
          endDate: adjustedEndDate,
        },
      });
    }
  };

  return (
    <div className="relative z-10 flex items-center">
      {dataObj && (
        <div className="grid grid-cols-2 gap-x-2">
          <p className="text-sm font-semibold">From</p>
          <p className="text-sm font-semibold">To</p>
          <p className="text-sm">
            {format(new Date(dataObj.startDate), 'dd MMM, yy')}
          </p>
          <p className="text-sm">
            {format(new Date(dataObj.endDate), 'dd MMM, yy')}
          </p>
        </div>
      )}
      <button
        onClick={toggleVisibility}
        className="cursor-pointer px-4 py-2 bg-white rounded-full flex items-center justify-between flex-1"
      >
        <img src="/fi-2278049.svg" alt="Calendar icon" className="h-5 w-5" />
      </button>
      {isOpen && (
        <div className="absolute top-full mt-2 w-auto right-0">
          <DateRangePicker
            onChange={handleSelection}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={[dataObj]}
            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))} // 2 days before today
            minDate={new Date(new Date().setMonth(new Date().getMonth() - 3))} // 3 months before today
            editableDateInputs={true}
          />
        </div>
      )}
    </div>
  );
};

const DateRange = withOutsideClick(DateRangeComponent);


export default DateRange;
