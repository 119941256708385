import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children, posthog }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (event) => {
      setHasError(true);
      if (posthog) {
        posthog.capture('error_occurred', {
          error: event.error.toString(),
          errorInfo: event.error.stack,
        });
      }
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, [posthog, children]);

  if (hasError) {
    return (
      <div className="error-boundary flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Something went wrong.
        </h2>
        <p className="text-lg text-gray-600 mb-6">Please try again later.</p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
