import React from 'react';
import { useTicketQueryStore } from '../../../state/useTicketQuery';

const SearchBar = () => {
  const {
    userId,
    noOfTickets,
    searchQuery,
    setTickets,
    setSearchQuery,
    setNoOfTickets,
    setSearchLoading,
    account,
    resetStore,
  } = useTicketQueryStore();
  const HOST_URL =
    process.env.REACT_APP_PUBLIC_TICKET_QUERY_HOST_URL ||
    'http://localhost:8000';

  const handleSearchQuery = async () => {
    try {
      setSearchLoading(true);
      const response = await fetch(
        `${HOST_URL}/tickets?user_id=${userId}&no_of_tickets=${noOfTickets}&query=${searchQuery}&account=${account}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      const jsonBody = JSON.parse(data.body);
      if (response.ok) {
        setTickets(jsonBody.tickets);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center w-[75%] space-x-4 px-4">
        <div className="flex flex-col flex-1 space-y-2">
          <label htmlFor="searchQuery" className="min-w-max">
            Search Query:
          </label>
          <input
            id="searchQuery"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-1 rounded-lg p-2 outline-none text-base"
            placeholder="Type your message here..."
          />
        </div>
        <div className="flex flex-col flex-3 space-y-2">
          <label htmlFor="noOfTickets" className="min-w-max">
            Number of Tickets:
          </label>
          <input
            id="noOfTickets"
            type="number"
            value={noOfTickets}
            onChange={(e) => setNoOfTickets(e.target.value)}
            className="flex-1 rounded-lg p-2 outline-none text-base"
            placeholder="Number of Tickets"
          />
        </div>
      </div>
      <div className="flex items-center mt-6">
        <button
          type="submit"
          className="font-semibold px-8 py-4 bg-blue-600 text-white rounded-2xl hover:bg-blue-700 focus:outline-none"
          onClick={handleSearchQuery}
        >
          FETCH TICKETS
        </button>
        <button
          className=" text-textPrimary p-4"
          onClick={() => {
            // navigate('/ticketQuery', { replace: true });
            resetStore();
            window.location.reload();
          }}
        >
          <p className="text-lg font-semibold">REFRESH</p>
        </button>
      </div>
    </>
  );
};

export default SearchBar;
