import React, { useState } from 'react';

const AddTimeLogModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');

    const handleDateChange = (e) => {
        setSelectedDate(new Date(e.target.value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return; // Prevent multiple submissions
        const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
        const durationInMilliseconds = totalMinutes * 60 * 1000;
        await onSubmit({
            date: selectedDate.getDate(),
            month: selectedDate.getMonth() + 1, // Adding 1 because getMonth() returns 0-11
            year: selectedDate.getFullYear(),
            duration: durationInMilliseconds
        });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Add Time Log</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2">Date:</label>
                        <input
                            type="date"
                            value={selectedDate.toISOString().split('T')[0]}
                            onChange={handleDateChange}
                            className="border p-2 rounded w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Duration:</label>
                        <div className="flex space-x-2">
                            <select
                                value={hours}
                                onChange={(e) => setHours(e.target.value)}
                                className="border p-2 rounded"
                            >
                                {[...Array(24).keys()].map((h) => (
                                    <option key={h} value={h}>
                                        {h} hour{h !== 1 ? 's' : ''}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={minutes}
                                onChange={(e) => setMinutes(e.target.value)}
                                className="border p-2 rounded"
                            >
                                {[...Array(12)].map((_, i) => {
                                    const m = i * 5;
                                    return (
                                        <option key={m} value={m}>
                                            {m} minute{m !== 1 ? 's' : ''}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 bg-slateblue text-white rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Adding...' : 'Add Log'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddTimeLogModal;