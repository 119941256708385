export const colors = [
  '#F4ADFF',
  '#AEBDFF',
  '#93DADF',
  '#FFBC3A',
  '#C9E6AB',
  '#687DFF',
  '#00A4A4',
  '#4BBF4A',
  '#FF7BAA',
  '#D0E2FF',
  '#A1EFFF',
  '#8B8BFF',
  '#FF5733',
  '#C70039',
  '#900C3F',
  '#FFC300',
  '#DAF7A6',
  '#D81B60',
  '#7E57C2',
  '#7CB342',
  '#26A69A',
  '#EF5350',
  '#AB47BC',
  '#FF7043',
  '#78909C',
  '#8D6E63',
  '#BDBDBD',
  '#FFF176',
  '#00BCD4',
  '#4DD0E1',
  '#26C6DA',
  '#E57373',
  '#81C784',
  '#FFD54F',
  '#BA68C8',
  '#9575CD',
  '#7986CB',
  '#64B5F6',
  '#4FC3F7',
  '#4DB6AC',
  '#81D4FA',
  '#80DEEA',
  '#A5D6A7',
  '#C5E1A5',
  '#E6EE9C',
  '#FFF59D',
  '#FFCC80',
  '#FFAB91',
  '#BCAAA4',
  '#EEEEEE',
];
